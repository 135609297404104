import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useMemo, useState } from "react";

export const useFilters = () => {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { replace } = useRouter();
	const [selectedFilters, setSelectedFilters] = useState<Array<string>>(
		searchParams.getAll("filter"),
	);

	const updateFilters = useCallback(
		(filters: Array<string>) => {
			setSelectedFilters(filters);
			const params = new URLSearchParams(searchParams.toString());
			params.delete("filter");
			filters.forEach((f) => params.append("filter", f));
			replace(`${pathname}?${params.toString()}`, { scroll: false });
		},
		[pathname, replace, searchParams],
	);

	const handlers = useMemo(
		() => ({
			handleSetFilter: (filter: string) => {
				updateFilters(
					selectedFilters.includes(filter)
						? selectedFilters.filter((f) => f !== filter)
						: [...selectedFilters, filter],
				);
			},
			handleClearFilters: () => {
				updateFilters([]);
			},
		}),
		[selectedFilters, updateFilters],
	);

	return [selectedFilters, handlers] as const;
};
