import React, { ImgHTMLAttributes } from "react";
import { Components } from "react-markdown";
import { z } from "zod";
import {
	QuoteAuthor as createQuoteAuthor,
	QuoteDescription as createQuoteDescription,
} from "./BlockQuoteRenderer";
import createCaption from "./Caption";
import createImage from "./Image";
import { Paragraph } from "../../../styles/FontFaces";
import { GridCenteredElement } from "../../styled/Grid";

const ParagraphRenderer: Components["p"] = ({ children, ...props }) => {
	const parsed = z
		.object({
			style: z
				.record(z.string().or(z.undefined()))
				.optional()
				.transform((v) => v as React.CSSProperties | undefined),
		})
		.safeParse(props);

	const style = parsed.success ? parsed.data.style : {};

	if (!children) {
		return null;
	}

	const childrenArray = React.Children.toArray(children);
	const firstChild = childrenArray[0];

	if (typeof firstChild === "string") {
		// checks for Image-Caption segment keyword 'caption'
		if (firstChild.includes("Caption:")) {
			// pass on sliced caption: element and rest of react nodes (to ensure correct formatting)
			return createCaption(firstChild.slice(8), childrenArray.slice(1));
		}

		// checks below: BlockQuote segment keywords 'QuoteAuthor', 'QuoteDescription' & 'QuoteSource'
		if (firstChild.includes("QuoteAuthor:")) {
			return createQuoteAuthor(
				firstChild.slice(13),
				childrenArray.slice(1),
			);
		}
		if (firstChild.includes("QuoteDescription:")) {
			return createQuoteDescription(
				firstChild.slice(18),
				childrenArray.slice(1),
			);
		}
		if (firstChild.includes("QuoteSource:")) {
			return createQuoteDescription(
				firstChild.slice(13),
				childrenArray.slice(1),
			);
		}
	} else if (React.isValidElement(firstChild) && firstChild.type === "img") {
		/* images need to be filtered here (not in markdown renderers)
  as we want them to be displayed without the react-markdown wrapper */
		const { src, alt } =
			firstChild.props as ImgHTMLAttributes<HTMLImageElement>;

		if (src && alt) {
			return createImage(src, alt);
		}
	}

	return (
		<GridCenteredElement>
			<Paragraph style={style}>{children}</Paragraph>
		</GridCenteredElement>
	);
};

export default ParagraphRenderer;
