import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { colors, fonts } from "../../../styles/variables";

const cardViewBp = mediaQuery["2xl"];

export const SlantedBox = styled(Box)`
	--slantOffset: 3rem;
	clip-path: polygon(0% var(--slantOffset), 0% 100%, 100% 100%, 100% 0%);

	${mediaQuery.sm} {
		--slantOffset: 4vw;
	}
`;

export const quotesStyles = () => css`
	position: absolute;
	width: 14rem !important;
	height: auto;
	bottom: calc(100% - 2rem);
	right: 2rem;

	opacity: 0.3;

	${mediaQuery.lg} {
		width: 24rem !important;
	}
	${cardViewBp} {
		z-index: -1;
		bottom: auto;
		top: -3rem;
		left: 2rem;
	}
`;

export const BlockQuote = styled(Box)`
	position: relative;
	margin: 0;
	padding-top: 4rem;
	padding-bottom: 4rem;

	font-size: ${fonts.fontSize.px24};
	font-weight: ${fonts.fontWeight.bold};
	color: ${colors.almostBlack};
	line-height: ${fonts.lineHeight.large};

	border-left: none;
	box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);

	> p,
	footer {
		margin: 0 10px;
		${mediaQuery.sm} {
			width: 60%;
			margin: auto;
		}
		${cardViewBp} {
			width: 100%;
		}
	}

	footer {
		padding-top: 3rem;
	}

	${cardViewBp} {
		position: absolute;
		width: 62rem;
		top: calc(50% + 6rem);
		transform: translateY(-50%);
		right: 6rem;

		padding: 9rem 7rem 7rem 7rem;
		font-size: ${fonts.fontSize.px32};
		background-color: ${colors.white};
	}
`;
