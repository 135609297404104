import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

export const InfoBlockContainer = styled.div`
	${mediaQuery.md} {
		width: 80ch;
	}
	${mediaQuery.lg} {
		padding: 60px;
		align-self: flex-end;
		z-index: ${dimensions.zIndex.aboveDefault};
		background-color: white;
		width: 35%;
		min-width: 500px;
		min-height: 500px;
		margin-top: 50px;
		margin-bottom: 50px;
		border-radius: 12px;
	}
`;
