import { cn } from "@peerigon/pupper/tailwind";
import React, { ReactNode } from "react";

type AnchorProps = { id?: string | null; children?: ReactNode };

const Anchor: React.FC<AnchorProps> = ({ id, children }) =>
	id ? (
		<div
			id={id}
			data-anchor
			// See https://darktef.github.io/posts/2017-03-offset-html-anchors-for-fixed-header-with-css
			className={cn(
				"scroll-mt-[150px] target:invisible target:relative target:-top-[90px] target:block target:scroll-mt-0 md:scroll-mt-[100px] md:target:-top-[150px] lg:target:-top-[100px]",
			)}
		>
			{children}
		</div>
	) : null;

export default Anchor;
