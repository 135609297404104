import React from "react";
import { Components } from "react-markdown";
import { ListContainer } from "../../ListContainer/ListContainer";
import { GridCenteredElement } from "../../styled/Grid";

const ListRenderer: Components["ul"] = (props) => {
	return (
		<GridCenteredElement>
			<ListContainer>{props.children}</ListContainer>
		</GridCenteredElement>
	);
};

export default ListRenderer;
