import { ExternalLinkIcon, SearchIcon } from "@chakra-ui/icons";
import {
	Box,
	Flex,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useLocale, useTranslations } from "next-intl";
import React, { memo, useCallback } from "react";
import { EventDate } from "./EventDate";
import { EventDates } from "./EventDates";
import { EventLinks } from "./EventLinks";
import { EventMeta, EventTitle } from "./EventMeta";
import { Paragraph, SmallText } from "../../../styles/FontFaces";
import { mediaQuery } from "../../../styles/mixins";
import { colors, dimensions } from "../../../styles/variables";
import Link from "../../Link/Link";
import { monthsShortName } from "../../helpers";

export type EventType = {
	data: {
		title: string;
		description: string;
		start: string;
		end: string;
		location: string;
		lat: number;
		lon: number;
	};
};

const eventContainerStyles = css`
	outline: none;
	${mediaQuery.xl} {
		flex-wrap: nowrap;
		align-items: center;
		height: 9.4rem;
		flex-direction: row;
	}

	&:last-of-type {
		border-bottom: none;
	}
`;

const popover = css`
	max-width: 300px;
	padding: 17px;
	border-radius: 5px;
	filter: drop-shadow(0px 0px 35px rgba(0, 0, 0, 0.18));
	background-color: ${colors.ice};
	overflow: visible;
	z-index: ${dimensions.zIndex.aboveDefault};

	:focus {
		box-shadow: none;
	}
`;

const noOutline = css`
	outline: none;
`;

// https://day.js.org/docs/en/plugin/utc
// we calculate our dates based on UTC to catch time shifts adding an addtional day for events (e.g 23:59 => +1 hour)
dayjs.extend(utc);

const Event: React.FC<{
	selected: boolean;
	event: EventType;
	onSelectEvent: (event: EventType | undefined) => void;
}> = ({ selected, event, onSelectEvent }) => {
	const t = useTranslations();
	const [twitterTag, eventLink] = event.data.description.split("|");
	const start = dayjs.utc(event.data.start);
	const end = dayjs.utc(event.data.end);
	const startMonthIndex = parseInt(start.format("M")) - 1;
	const endMonthIndex = parseInt(end.format("M")) - 1;

	const handleSelectEvent = useCallback(() => {
		if (!selected) {
			onSelectEvent(event);
		}
	}, [event, onSelectEvent, selected]);

	return (
		<Flex
			as="li"
			maxWidth="100%"
			backgroundColor={selected ? colors.moonRaker : colors.white}
			padding="1.5rem 0.5rem 1.5rem 2.4rem"
			borderBottom={`1px solid ${colors.gray.line}`}
			height="16.4rem"
			justifyContent="space-between"
			flexWrap="wrap"
			css={eventContainerStyles}
			tabIndex={0}
			onKeyPress={handleSelectEvent}
			onClick={handleSelectEvent}
		>
			<EventDates>
				<EventDate selected={selected}>
					<span>{start.format("D")}</span>
					<span>
						{monthsShortName[useLocale()]?.[startMonthIndex]}
					</span>
					<span>{start.format("YYYY")}</span>
				</EventDate>
				<svg
					width="22"
					height="22"
					viewBox="0 0 22 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1 20.5566L21 0.556641"
						stroke={
							selected ? colors.almostBlack : colors.gray.text
						}
						strokeWidth="1.5"
					/>
				</svg>
				<EventDate selected={selected}>
					<span>{end.format("D")}</span>
					<span>{monthsShortName[useLocale()]?.[endMonthIndex]}</span>
					<span>{end.format("YYYY")}</span>
				</EventDate>
			</EventDates>
			<EventMeta>
				<EventTitle title={event.data.title}>
					{event.data.title}
				</EventTitle>
				<SmallText title={event.data.location}>
					{event.data.location}
				</SmallText>
			</EventMeta>

			<Flex
				justifyContent="flex-end"
				width={["50%", "50%", "50%", "50%", "unset"]}
			>
				<Popover placement="bottom-end" variant="responsive">
					<PopoverTrigger>
						<EventLinks
							aria-label={t("findOutMore")}
							css={noOutline}
						>
							<svg viewBox="0 0 44 44">
								<circle
									cx="20"
									cy="4"
									r="4"
									fill={colors.blueberry}
								/>
								<circle
									cx="20"
									cy="20"
									r="4"
									fill={colors.blueberry}
								/>
								<circle
									cx="20"
									cy="36"
									r="4"
									fill={colors.blueberry}
								/>
							</svg>
						</EventLinks>
					</PopoverTrigger>
					<PopoverContent css={popover}>
						<PopoverBody overflow="hidden">
							{eventLink && (
								<Flex as={Paragraph}>
									<Box as="span" marginRight="8px">
										<ExternalLinkIcon />
									</Box>
									<Link
										title={event.data.title}
										target="_blank"
										to={eventLink.trim()}
									>
										{event.data.title}
									</Link>
								</Flex>
							)}
							{twitterTag && (
								<Flex as={Paragraph}>
									<Box as="span" marginRight="8px">
										<SearchIcon />
									</Box>
									<Link
										title={twitterTag}
										target="_blank"
										to={`https://twitter.com/search?q=(${encodeURIComponent(
											twitterTag,
										)})&src=typed_query`}
									>
										{twitterTag}
									</Link>
								</Flex>
							)}
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Flex>
		</Flex>
	);
};

export default memo(Event);
