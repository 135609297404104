import { cn } from "@peerigon/pupper/tailwind";
import { Markdown } from "src/components/sections/MarkdownSection/MarkdownSection";
import { Hx } from "uberschrift";

type IntroBlockProps = {
	headingAddOn?: string;
	heading: string;
	description?: string;
	className?: string;
	children?: React.ReactNode;
};

const IntroBlock: React.FC<IntroBlockProps> = ({
	headingAddOn,
	heading,
	description,
	className,
	children,
}) => {
	return (
		<div
			className={cn(
				"flex flex-col items-center gap-4 md:gap-6",
				className,
			)}
		>
			{headingAddOn ? (
				<div className="text-size-6 text-center">{headingAddOn}</div>
			) : null}

			<Hx className="m-0 text-center text-[clamp(3.2rem,3vw_+_1rem,4.4rem)] font-bold leading-regular-3 text-[inherit] md:leading-regular-1">
				{heading}
			</Hx>

			{description ? (
				<div className="mx-auto max-w-[80rem] [&_div]:last-of-type:m-0 [&_p]:text-center [&_p]:text-base [&_p]:font-light [&_p]:leading-larger [&_p]:md:text-xl">
					<Markdown source={description} />
				</div>
			) : null}

			{children}
		</div>
	);
};

export default IntroBlock;
