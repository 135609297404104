"use client";

import { cn, tw } from "@peerigon/pupper/tailwind";
import { useTranslations } from "next-intl";
import React from "react";
import Loading from "src/assets/loading.svg";
import RocketStartup from "src/assets/rocketStartup.svg";
import ConsentAwareWrapper from "src/components/Iubenda/ConsentAwareWrapper";
import { useIubenda } from "src/contexts/IubendaContext";
import type { IubendaContext } from "src/contexts/IubendaContext";

type ConsentGuardProps = {
	children: React.ReactNode;
	requiredGdprPurposes: Array<
		keyof IubendaContext["userPreferences"]["gdprPurposes"]
	>;
	containerClasses?: string;
};

const consentContainerClasses = tw`flex flex-col items-center gap-4 px-20 py-6 text-center text-xl font-light leading-larger`;

const ConsentNotGranted: React.FC<{ containerClasses?: string }> = ({
	containerClasses,
}) => {
	const iubendaContext = useIubenda();
	const t = useTranslations();

	return (
		<div className={cn(consentContainerClasses, containerClasses)}>
			<RocketStartup />
			{t("consentGuardNotGranted")}
			<button
				type="button"
				onClick={() => iubendaContext.openPreferences()}
				className="rounded-full border bg-gray-nav px-3 py-2 text-black transition-colors hover:bg-black hover:text-white focus:border-black"
			>
				{t("consentGuardOpenPreferences")}
			</button>
		</div>
	);
};

const ConsentStateLoading: React.FC<{ containerClasses?: string }> = ({
	containerClasses,
}) => {
	const t = useTranslations();

	return (
		<div className={cn(consentContainerClasses, containerClasses)}>
			<Loading />
			{t("consentLoading")}
		</div>
	);
};

const ConsentGuard: React.FC<ConsentGuardProps> = ({
	children,
	requiredGdprPurposes,
	containerClasses,
}) => {
	return (
		<ConsentAwareWrapper
			requiredGdprPurposes={requiredGdprPurposes}
			customConsentNotGrantedNodes={
				<ConsentNotGranted containerClasses={containerClasses} />
			}
			customLoadingNodes={
				<ConsentStateLoading containerClasses={containerClasses} />
			}
		>
			{children}
		</ConsentAwareWrapper>
	);
};

export default ConsentGuard;
