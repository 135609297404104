"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import NavLeftIcon from "src/assets/navLeft.svg";
import NavRightIcon from "src/assets/navRight.svg";
import AgendaEntry from "src/components/sections/AgendaSection/AgendaEntry";
import {
	AgendaTabs,
	AgendaTabsContent,
	AgendaTabsList,
	AgendaTabsNav,
	AgendaTabsScrollArea,
	AgendaTabsTrigger,
} from "src/components/sections/AgendaSection/AgendaTabs";

type AgendaProps = {
	tabs: Array<{
		id: string;
		label?: string;
		entries: Array<{
			label?: string;
			description: string;
			isInactive?: boolean;
		}>;
	}>;
};

const Agenda: React.FC<AgendaProps> = ({ tabs }) => {
	const prevButtonRef = useRef<HTMLButtonElement | null>(null);
	const nextButtonRef = useRef<HTMLButtonElement | null>(null);
	const tabsContainerRef = useRef<HTMLDivElement | null>(null);
	const tabListRef = useRef<HTMLDivElement | null>(null);
	const [activeTab, setActiveTab] = useState(tabs.at(0)?.id);
	const tabsTriggerRefs = useRef<Record<string, HTMLButtonElement | null>>(
		{},
	);

	const scrollToTab = useCallback((activeTab: string) => {
		requestAnimationFrame(() => {
			tabsTriggerRefs.current[activeTab]?.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "start",
			});
		});
	}, []);

	useEffect(() => {
		const handleResize = () => {
			const tabContainerWidth = tabsContainerRef.current?.clientWidth;
			const tabListWidth = tabListRef.current?.clientWidth;

			const shouldDisplayNavButtons =
				tabContainerWidth &&
				tabListWidth &&
				tabContainerWidth < tabListWidth;

			if (shouldDisplayNavButtons) {
				prevButtonRef.current?.classList.remove("hidden");
				nextButtonRef.current?.classList.remove("hidden");
			} else {
				prevButtonRef.current?.classList.add("hidden");
				nextButtonRef.current?.classList.add("hidden");
			}
		};
		if (activeTab && !tabs.map(({ id }) => id).includes(activeTab)) {
			const newActiveTab = tabs.at(-1)?.id;
			if (newActiveTab) setActiveTab(newActiveTab);
		}
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [activeTab, tabs]);

	if (!tabs.length) return null;

	return (
		<AgendaTabs value={activeTab} onValueChange={setActiveTab}>
			{tabs.length > 1 ? (
				<div ref={tabsContainerRef} className="flex items-center gap-1">
					<AgendaTabsNav
						ref={prevButtonRef}
						disabled={activeTab === tabs.at(0)?.id}
						onClick={() => {
							const activeTabIndex = tabs.findIndex(
								(tab) => tab.id === activeTab,
							);
							const previousTab = tabs[activeTabIndex - 1];
							if (activeTabIndex > 0 && previousTab) {
								setActiveTab(previousTab.id);
								scrollToTab(previousTab.id);
							}
						}}
					>
						<NavLeftIcon />
					</AgendaTabsNav>

					<AgendaTabsScrollArea>
						<AgendaTabsList ref={tabListRef}>
							{tabs.map(({ id, label }) => (
								<AgendaTabsTrigger
									key={id}
									ref={(element) => {
										if (element) {
											tabsTriggerRefs.current[id] =
												element;
										}
									}}
									value={id}
									onFocus={() => scrollToTab(id)}
								>
									{label ?? id}
								</AgendaTabsTrigger>
							))}
						</AgendaTabsList>
					</AgendaTabsScrollArea>

					<AgendaTabsNav
						ref={nextButtonRef}
						disabled={activeTab === tabs.at(-1)?.id}
						onClick={() => {
							const activeTabIndex = tabs.findIndex(
								(tab) => tab.id === activeTab,
							);
							const nextTab = tabs[activeTabIndex + 1];
							if (activeTabIndex < tabs.length - 1 && nextTab) {
								setActiveTab(nextTab.id);
								scrollToTab(nextTab.id);
							}
						}}
					>
						<NavRightIcon />
					</AgendaTabsNav>
				</div>
			) : null}

			{tabs.map(({ id, entries }) => {
				return (
					<AgendaTabsContent key={id} value={id}>
						{entries.map((entry) => (
							<AgendaEntry
								key={`${id}-${entry.label}-${entry.description}`}
								label={entry.label}
								description={entry.description}
								isInactive={entry.isInactive}
							/>
						))}
					</AgendaTabsContent>
				);
			})}
		</AgendaTabs>
	);
};

export default Agenda;
