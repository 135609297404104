import { cn } from "@peerigon/pupper/tailwind";
import { useLocale, useTranslations } from "next-intl";
import { getFragmentData, graphql } from "src/__generated__";
import { PageType } from "src/__generated__/graphql";
import Link from "src/components/Link/Link";
import PolicyDialog from "src/components/PolicyDialog/PolicyDialog";
import { useIubenda } from "src/contexts/IubendaContext";
import { IUBENDA_COOKIE_POLICY_ID } from "src/lib/iubendaConfiguration";
import type { FragmentType } from "src/__generated__";
import type { FooterPageFieldsFragmentDoc } from "src/__generated__/graphql";
import type { Locale } from "src/i18n/routing";

const FooterPageFieldsFragment = graphql(`
	fragment FooterPageFields on Page {
		routeName
		title
		shortTitle
		type
	}
`);

const FooterSecondaryLinks: React.FC<{
	type: PageType;
	pages: Array<FragmentType<typeof FooterPageFieldsFragmentDoc>>;
}> = ({ type, pages }) => {
	const pagesFragment = getFragmentData(FooterPageFieldsFragment, pages);

	const t = useTranslations();
	const locale = useLocale() as Locale;

	const { openPreferences } = useIubenda();

	return (
		<div
			className={cn(
				"flex flex-col",
				type === PageType.legal
					? "gap-3 md:flex-row md:gap-8"
					: "gap-4",
			)}
		>
			{type === PageType.legal ? (
				<PolicyDialog
					label={t("privacyPolicy")}
					url={`https://www.iubenda.com/privacy-policy/${IUBENDA_COOKIE_POLICY_ID[locale]}`}
				/>
			) : null}

			{type === PageType.legal ? (
				<PolicyDialog
					label={t("cookiePolicy")}
					url={`https://www.iubenda.com/privacy-policy/${IUBENDA_COOKIE_POLICY_ID[locale]}/cookie-policy`}
				/>
			) : null}

			{pagesFragment.map((page) => {
				return (
					<Link
						key={page.routeName}
						to={page.routeName}
						className={cn(
							"text-base font-light disable-anchor-shadow-styles hover:underline focus:underline",
							type === PageType.legal
								? "whitespace-nowrap leading-larger"
								: "leading-regular-3",
						)}
					>
						{page.shortTitle ?? page.title}
					</Link>
				);
			})}

			{type === PageType.legal && (
				<button
					type="button"
					onClick={() => openPreferences()}
					className="cursor-pointer whitespace-nowrap text-base font-light leading-larger disable-anchor-shadow-styles hover:underline focus:underline"
				>
					{t("showPrivacyPreferences")}
				</button>
			)}
		</div>
	);
};

export default FooterSecondaryLinks;
