"use client";
/* eslint-disable react/jsx-no-literals */
/* eslint-disable @next/next/no-img-element */
import { Spinner } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import React, { useEffect, useMemo, useState } from "react";
import { useBoolean } from "react-use";
import ConsentGuard from "src/components/ConsentGuard/ConsentGuard";
import { CodeSandBoxEmbed } from "./CodeSandBox";
import {
	Embed,
	Error,
	Loading,
	tweetWrapperStyles,
	Wrapper,
	wrapperStyles,
} from "./OEmbedContainer";
import Tweet from "./Tweet";
import getEmbedData, { EmbedData } from "./noEmbedApi";

const isImageUrl = (url: string): boolean => {
	const extension = url.split(".").pop();

	return Boolean(
		extension && ["gif", "jpg", "jpeg", "png", "webp"].includes(extension),
	);
};

const OEmbedError: React.FC<{ url: string; message: string }> = ({
	url,
	message,
}) => (
	<Error>
		<a href={url} target="_blank" rel="noopener noreferrer">
			{new URL(url).hostname}
		</a>
		<small>({message})</small>
	</Error>
);

const OEmbed: React.FC<{ url: string }> = ({ url }) => {
	const [oEmbedData, setOEmbedData] = useState<EmbedData | null>(null);
	const [error, setError] = useBoolean(false);

	const t = useTranslations();

	const isTweet = url.includes("//twitter.com/");
	const isCSB = url.includes("//codesandbox.io");
	const isImage = isImageUrl(url);

	// we have custom logic for CodeSandbox and for images we just display them
	const isStatic = isImage || isCSB;
	const isElse = !isTweet && !isCSB && !isImage;

	// lazily load data from API
	useEffect(() => {
		if (isStatic) {
			return;
		}

		(async () => {
			try {
				const data = await getEmbedData(url);

				setOEmbedData(data);
			} catch (_) {
				setError(true);
			}
		})();
	}, [setError, url, isStatic]);

	const loading = !isStatic && !oEmbedData && !error;

	const providerName = useMemo(() => {
		if (isImage) {
			return "image";
		}
		if (isCSB) {
			return "codesandbox";
		}
		if (isTweet) {
			return "twitter";
		}
		if (oEmbedData?.provider_name) {
			return oEmbedData.provider_name.toLowerCase();
		}

		return "unknown";
	}, [oEmbedData, isImage, isCSB, isTweet]);

	return (
		<div>
			<ConsentGuard requiredGdprPurposes={["experience"]}>
				<Wrapper
					css={isTweet ? tweetWrapperStyles : wrapperStyles}
					data-oembed-provider={providerName}
					data-chromatic="ignore"
				>
					{loading && (
						<Loading>
							<Spinner />
						</Loading>
					)}
					{error && (
						<OEmbedError url={url} message={t("noEmbedFound")} />
					)}
					{!loading && !error && (
						<>
							{isImage && <img src={url} alt={providerName} />}

							{isTweet && oEmbedData?.html && (
								<Tweet html={oEmbedData.html} />
							)}

							{isCSB && <CodeSandBoxEmbed url={url} />}

							{isElse &&
								(oEmbedData?.html ? (
									<Embed
										dangerouslySetInnerHTML={{
											__html: oEmbedData.html,
										}}
									/>
								) : (
									<OEmbedError
										url={url}
										message={t("noEmbedFound")}
									/>
								))}
						</>
					)}
				</Wrapper>
			</ConsentGuard>
		</div>
	);
};

export default OEmbed;
