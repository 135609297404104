"use client";

import { cn } from "@peerigon/pupper/tailwind";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import CarouselNavigation from "src/components/Card/CarouselNavigation";
import CmsImage from "src/components/Image/CmsImage";
import { getImageAlt } from "src/components/server.helpers";
import type { UseEmblaCarouselType } from "embla-carousel-react";
import type { CmsImageFieldsFragment } from "src/__generated__/graphql";

type CardCarouselProps = {
	images: Array<CmsImageFieldsFragment>;
	isMaximized?: boolean;
	startIndex?: number;
	onUpdateStartIndex?: (startIndex: number) => void;
	onMaximize?: VoidFunction;
};

type CarouselApi = UseEmblaCarouselType[1];

const CardCarousel: React.FC<CardCarouselProps> = ({
	images,
	startIndex = 1,
	isMaximized = false,
	onUpdateStartIndex,
	onMaximize,
}) => {
	const [carouselRef, api] = useEmblaCarousel({
		active: images.length > 1,
		startIndex,
	});

	const [canScrollPrev, setCanScrollPrev] = useState(false);
	const [canScrollNext, setCanScrollNext] = useState(false);
	const [selectedScrollSnap, setSelectedScrollSnap] = useState(startIndex);

	const onSelect = useCallback((api: CarouselApi) => {
		if (!api) return;
		setCanScrollPrev(api.canScrollPrev());
		setCanScrollNext(api.canScrollNext());
		setSelectedScrollSnap(api.selectedScrollSnap());
	}, []);

	const handleScrollPrev = useCallback(() => {
		api?.scrollPrev();
	}, [api]);

	const handleScrollNext = useCallback(() => {
		api?.scrollNext();
	}, [api]);

	useEffect(() => {
		if (api) onSelect(api);
		api?.on("reInit", onSelect);
		api?.on("select", onSelect);
		return () => {
			api?.off("select", onSelect);
		};
	}, [api, onSelect]);

	return (
		<div
			className="relative h-full w-full"
			role="region"
			aria-roledescription="carousel"
		>
			<div
				className="h-full w-full overflow-hidden @8xl:rounded-lg"
				ref={carouselRef}
			>
				<div className="flex h-full w-full">
					{images.map((imageData) => (
						<div
							key={imageData.fileName}
							className={cn("min-w-0", {
								"flex-[0_0_100%]":
									!isMaximized || images.length === 1,
								"[&:first-child]:pl-28 [&:last-child]:pr-28 [&:not(:first-child)]:pl-8":
									isMaximized,
								"flex-[0_0_80%]":
									isMaximized && images.length > 1,
							})}
							role="group"
							aria-roledescription="slide"
						>
							<CmsImage
								image={imageData}
								alt={getImageAlt(imageData)}
								className={cn(
									"h-full w-full bg-gray-100 object-cover @8xl:min-h-80 @8xl:rounded-lg",
									{
										"max-h-[75vh] rounded-lg": isMaximized,
									},
								)}
							/>
						</div>
					))}
				</div>
			</div>
			<div
				ref={(node) => {
					if (node?.clientWidth) {
						node.style.setProperty(
							"--carousel-nav-width",
							`${node.clientWidth}px`,
						);
					}
				}}
				className={`absolute flex border-red-50 ${isMaximized ? "bottom-[-60px] left-[calc(50%-calc(var(--carousel-nav-width)/2))]" : "bottom-[10px] right-[10px]"}`}
			>
				<CarouselNavigation
					isMaximized={isMaximized}
					canScrollNext={canScrollNext}
					canScrollPrev={canScrollPrev}
					onScrollNext={handleScrollNext}
					onScrollPrev={handleScrollPrev}
					onMaximize={() => {
						if (!isMaximized) {
							onUpdateStartIndex?.(selectedScrollSnap);
						}
						onMaximize?.();
					}}
				/>
			</div>
		</div>
	);
};

export default CardCarousel;
