import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FontSize } from "src/__generated__/graphql";
import { mediaQuery, italic } from "../../../styles/mixins";
import { breakpoints, fonts } from "../../../styles/variables";

type SizeDict = {
	[key in keyof typeof breakpoints]: {
		[key in FontSize]: string;
	};
};

export const sizeDict: SizeDict = {
	// viewport breakpoints
	xs: {
		// font size from CMS
		sm: "10vw",
		md: "12vw",
		lg: "16vw",
		xl: "20vw",
	},
	sm: {
		sm: "10vw",
		md: "12vw",
		lg: "16vw",
		xl: "20vw",
	},
	md: {
		sm: fonts.fontSize.px35,
		md: fonts.fontSize.px40,
		lg: fonts.fontSize.px44,
		xl: fonts.fontSize.px68,
	},
	lg: {
		sm: "4vw",
		md: "8vw",
		lg: fonts.fontSize.px110,
		xl: fonts.fontSize.px140,
	},
	xl: {
		sm: fonts.fontSize.px44,
		md: fonts.fontSize.px110,
		lg: fonts.fontSize.px110,
		xl: fonts.fontSize.px220,
	},
	"2xl": {
		sm: fonts.fontSize.px44,
		md: fonts.fontSize.px110,
		lg: fonts.fontSize.px110,
		xl: fonts.fontSize.px220,
	},
};

type SizeProps = { fontSize: FontSize };

export const StyledFigureContainer = styled("p", {
	shouldForwardProp: isPropValid,
})<SizeProps>`
	flex-grow: 1;
	display: flex;
	align-items: center;
	margin: 0;
	gap: ${
		({ fontSize }) =>
			fontSize === FontSize.xl
				? 0
				: `0.1ch` /* reduce too large gap on 'xl' font sizes */
	};

	${({ fontSize }) =>
		Object.keys(breakpoints).map(
			(bp) => css`
				${mediaQuery[bp as keyof typeof breakpoints]} {
					font-size: ${sizeDict[bp as keyof typeof breakpoints][
						fontSize
					]};
				}
			`,
		)}
`;

export const StyledFigurePreSuffix = styled("span", {
	shouldForwardProp: isPropValid,
})<{ isItalic: boolean }>`
	font-size: 0.5em;
	font-weight: lighter;
	${({ isItalic }) => (isItalic ? italic : "")};
`;
