import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { mediaQuery } from "./mixins";
import { fonts, textPresets } from "./variables";

export const Paragraph = styled("p", {
	shouldForwardProp: isPropValid,
})<{
	textAlign?: string;
}>`
	font-family: ${fonts.adelphi};
	font-weight: ${textPresets.size7.fontWeight};
	font-size: ${textPresets.size7.fontSize};
	line-height: ${textPresets.size7.lineHeight};
	margin: 0;

	${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

	${mediaQuery.lg} {
		font-size: ${textPresets.size7.fontSizeLg};
	}
`;

export const SmallText = styled("p", {
	shouldForwardProp: isPropValid,
})<{
	textAlign?: string;
	color?: string;
}>`
	font-size: ${fonts.fontSize.px14};
	font-weight: ${fonts.fontWeight.regular};
	line-height: ${fonts.lineHeight.large};
	${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
	${({ color }) => color && `color: ${color};`}
  margin: 0;

	${mediaQuery.lg} {
		font-size: ${fonts.fontSize.px16};
	}
`;

export const TinyHeadline = styled("p", {
	shouldForwardProp: isPropValid,
})<{ color?: string }>`
	font-family: ${fonts.adelphi};
	font-size: ${fonts.fontSize.px13};
	font-weight: ${fonts.fontWeight.regular};
	letter-spacing: ${fonts.letterSpacing.tiny};
	text-transform: uppercase;
	${({ color }) => color && `color: ${color};`}
`;

export const Author = styled.p`
	font-size: ${fonts.fontSize.px16};
	font-weight: ${fonts.fontWeight.stronger};
	line-height: ${fonts.lineHeight.regular2};
	letter-spacing: ${fonts.letterSpacing.widest};
	text-transform: uppercase;
	font-style: normal;

	${mediaQuery.lg} {
		font-size: ${fonts.fontSize.px20};
	}
`;
