"use client";

import styled from "@emotion/styled";
import dynamic from "next/dynamic";
import React, { useState } from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import AnimationContainer from "./AnimationContainer";
import ScrollNudge from "./ScrollNudge";
import StyledHeader from "./StyledHeader";
import TextContainer from "./TextContainer";
import { mediaQuery } from "../../../styles/mixins";
import { colors, fonts, textPresets } from "../../../styles/variables";
import { shuffleArray } from "../../helpers";

const AnimatedSlogans = dynamic(async () => import("./AnimatedSlogans"), {
	ssr: false,
});

const StyledH1 = styled.h1`
	font-size: ${textPresets.size5.fontSize};
	line-height: ${textPresets.size5.lineHeight};
	letter-spacing: ${textPresets.size5.letterSpacing};
	text-transform: uppercase;
	font-style: normal;
	font-weight: ${fonts.fontWeight.light};
	color: ${colors.almostBlack};
	display: block;
	padding-bottom: 8px;
	margin: 0;

	${mediaQuery.lg} {
		padding-bottom: 12px;
		font-size: ${textPresets.size5.fontSizeLg};
	}
`;

type HomeIntroSectionProps = {
	section: FragmentType<typeof HomeIntroSectionFragment>;
};

export const HomeIntroSectionFragment = graphql(`
	fragment HomeIntroSectionItem on HomeIntroSection {
		id
		mainTitle
		slogans: simpleTextGroups {
			id
			fromText: text1
			line1: text2
			toText: text3
			line2: text4
		}
	}
`);

export const HomeIntroSection: React.FC<HomeIntroSectionProps> = ({
	section,
}) => {
	const { mainTitle, slogans } = getFragmentData(
		HomeIntroSectionFragment,
		section,
	);

	const [slogansShuffled] = useState(shuffleArray(slogans));

	return (
		<StyledHeader>
			<AnimationContainer>
				<TextContainer>
					<StyledH1>{mainTitle}</StyledH1>
					<AnimatedSlogans slogans={slogansShuffled} />
				</TextContainer>
				<ScrollNudge />
			</AnimationContainer>
		</StyledHeader>
	);
};
