"use client";

import { getFragmentData, graphql } from "src/__generated__";
import { NavAnchorLinkColor } from "src/__generated__/graphql";
import DesktopNavigationWrapper from "src/components/Navigation/DesktopNavigationWrapper";
import { useChangeLocale } from "src/components/Navigation/Navigation.hooks";
import NavigationWrapper from "src/components/Navigation/NavigationWrapper";
import LandingPageNavLinks from "src/components/sections/LandingPageHeaderSection/LandingPageNavLinks";
import type { FragmentType } from "src/__generated__";

export const LandingPageHeaderSectionFragment = graphql(`
	fragment LandingPageHeaderSectionItem on LandingPageHeaderSection {
		id
		navAnchorLinks {
			...NavAnchorLinkItem
		}
	}
`);

export const NavAnchorLinkItemFragment = graphql(`
	fragment NavAnchorLinkItem on NavAnchorLink {
		id
		label
		anchor
		color
	}
`);

export const LandingPageHeaderSection: React.FC<{
	section: FragmentType<typeof LandingPageHeaderSectionFragment>;
	disableLanguageSwitch?: boolean;
	localizedPathname: Record<string, Record<string, string>>;
}> = ({ section, disableLanguageSwitch, localizedPathname }) => {
	const { navAnchorLinks } = getFragmentData(
		LandingPageHeaderSectionFragment,
		section,
	);
	const { pathname, onChangeLocale } = useChangeLocale(localizedPathname);

	const navLinks = navAnchorLinks.map((link) => {
		const { anchor, label, color } = getFragmentData(
			NavAnchorLinkItemFragment,
			link,
		);
		return { anchor, label, color: color ?? NavAnchorLinkColor.none };
	});

	return (
		<NavigationWrapper>
			<DesktopNavigationWrapper
				mainNav={
					<LandingPageNavLinks
						links={navLinks}
						// Remove leading slash to enable Link component to add locale automatically
						pathname={pathname.substring(1)}
					/>
				}
				disableLanguageSwitch={disableLanguageSwitch}
				onChangeLocale={onChangeLocale}
			/>
		</NavigationWrapper>
	);
};
