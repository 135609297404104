import React from "react";
import { Components } from "react-markdown";
import { buttonVariants } from "src/components/ButtonNew/Button";
import Link from "../../Link/Link";

/**
 * if you want to use a button style link in markdown, do it like:
 * [[[button label]]](https://...)
 */
const LinkRenderer: Components["a"] = ({ href, children }) => {
	if (href === undefined) {
		return <>{children}</>;
	}

	const childStr = String(children);
	const isButton = childStr.startsWith("[[") && childStr.endsWith("]]");
	const linkLabel = isButton
		? childStr.substring(2, childStr.length - 2)
		: children;

	return isButton ? (
		<Link
			to={href}
			className={buttonVariants({
				isLink: true,
				className: "m-[1rem_1rem_0_0]",
			})}
		>
			{linkLabel}
		</Link>
	) : (
		<Link to={href}>{linkLabel}</Link>
	);
};

export default LinkRenderer;
