import { useTranslations } from "next-intl";
import { useCallback } from "react";
import { useCopyToClipboard } from "react-use";
import { toast } from "sonner";
import ShareIcon from "src/assets/share.svg";
import Button from "src/components/ButtonNew/Button";
import ShareToast from "src/components/ShareButton/ShareToast";
import { useOneOffAnimationClass } from "src/components/helpers";

type ShareButtonProps = {
	slug: string;
	onClick?: VoidFunction;
};

export const SHARE_TOAST_DURATION = 1_000;

const ShareButton: React.FC<ShareButtonProps> = ({
	slug,
	onClick = () => {},
}) => {
	const t = useTranslations();
	const [state, copyToClipboard] = useCopyToClipboard();
	const [ref, triggerHighlight] = useOneOffAnimationClass<HTMLButtonElement>(
		"share-feedback",
		1_000,
	);

	const handleClick = useCallback(() => {
		triggerHighlight();
		onClick();
		const params = new URLSearchParams(window.location.search);
		params.set("highlight", slug);
		const url = `${window.location.origin}${window.location.pathname}?${params.toString()}#${slug}`;

		copyToClipboard(url);
		toast(<ShareToast label={t("copiedToClipboard")} />, {
			position: "bottom-center",
			duration: SHARE_TOAST_DURATION,
			unstyled: true,
		});
	}, [copyToClipboard, onClick, slug, t, triggerHighlight]);

	return (
		<Button
			ref={ref}
			variant="ghost"
			className="[&.share-feedback]:bg-mint [&.share-feedback]:text-white [&.share-feedback]:transition [&.share-feedback]:duration-1000 [&.share-feedback]:ease-in-out [&.share-feedback]:hover:bg-mint"
			onClick={handleClick}
			data-copytoclipboard={state.error ?? "ok"}
		>
			<ShareIcon /> {t("share")}
		</Button>
	);
};

export default ShareButton;
