"use client";

import { useTranslations } from "next-intl";
import XIcon from "src/assets/x-icon.svg";
import Button from "src/components/ButtonNew/Button";
import Filter from "src/components/Filter/Filter";
import { useFilters } from "src/components/sections/CaseStudyListSection/CaseStudyListSection.hooks";

type DesktopFiltersBarProps = {
	filters: Array<string>;
};

const DesktopFiltersBar: React.FC<DesktopFiltersBarProps> = ({ filters }) => {
	const t = useTranslations();
	const [selectedFilters, { handleSetFilter, handleClearFilters }] =
		useFilters();

	return (
		<div className="hidden md:block">
			<div className="flex items-start gap-5">
				<div className="flex flex-1 flex-wrap gap-3">
					{filters.map((filter) => (
						<Filter
							key={filter}
							isActive={selectedFilters.includes(filter)}
							onClick={() => handleSetFilter(filter)}
						>
							{filter}
						</Filter>
					))}
				</div>
				<Button
					variant="ghost"
					disabled={selectedFilters.length === 0}
					onClick={handleClearFilters}
				>
					<XIcon /> {t("clearFilters")}
				</Button>
			</div>
		</div>
	);
};

export default DesktopFiltersBar;
