import React, { ReactNode } from "react";
import { Code } from "./Code";
import OEmbed from "../../OEmbed/OEmbed";
import { Spoiler } from "../../Spoiler/Spoiler";
import { GridCenteredElement } from "../../styled/Grid";

const CodeRenderer: React.FC<{ language?: string; children?: ReactNode }> = ({
	language,
	children,
}) => {
	if (children == null) {
		return <div />;
	}
	if (language === "spoiler") {
		return (
			<GridCenteredElement>
				<Spoiler>{children}</Spoiler>
			</GridCenteredElement>
		);
	}
	if (language === "embed" || language === "iframe") {
		return (
			<GridCenteredElement>
				<OEmbed url={String(children)} />
			</GridCenteredElement>
		);
	}

	if (language === "optout") {
		return (
			<GridCenteredElement>
				<iframe
					src={String(children)}
					frameBorder="0"
					title="Matomo Opt Out"
					width="100%"
					height="400px"
				/>
			</GridCenteredElement>
		);
	}

	return (
		<Code
			language={language ?? ""}
			code={children as (string | Array<string>) & ReactNode}
		/>
	);
};

export default CodeRenderer;
