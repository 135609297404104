"use client";

import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { InfoBlockContainer } from "./InfoBlockContainer";
import { StyledIcon } from "./StyledIcon";
import { SmallText } from "../../../styles/FontFaces";
import { textPresets } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import Title from "../../Title/Title";
import { getImageAlt } from "../../server.helpers";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const InfoBlockSectionFragment = graphql(`
	fragment InfoBlockSectionItem on InfoBlockSection {
		__typename
		id
		anchor
		spacingTop
		spacingBottom
		image {
			...CmsImageFields
		}
		headline
		listItems {
			id
			infoText: text
			headline
			icon {
				...CmsImageFields
			}
		}
	}
`);

export const InfoBlockSection: React.FC<{
	section: FragmentType<typeof InfoBlockSectionFragment>;
}> = ({ section }) => {
	const { anchor, image, headline, listItems, spacingTop, spacingBottom } =
		getFragmentData(InfoBlockSectionFragment, section);
	const resolvedImage = getFragmentData(CmsImageFields, image);
	const alt = getImageAlt(resolvedImage);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<div className="relative">
				<ContentContainer>
					<InfoBlockContainer>
						<Title headingLevel="h3" margin="0 0 40px 0">
							{headline}
						</Title>
						<div className="mb-[33px] flex flex-col gap-[33px] lg:mb-0">
							{listItems.map(
								({ id, infoText, icon, headline }) => {
									const resolvedIcon = getFragmentData(
										CmsImageFields,
										icon,
									);

									return (
										<div key={id}>
											{(headline || resolvedIcon) && (
												<div className="mb-[12px] flex items-center text-almostBlack">
													{resolvedIcon && (
														<StyledIcon
															src={
																resolvedIcon.url
															}
															loading="lazy"
															height={
																resolvedIcon.height ??
																"45px"
															}
															width={
																resolvedIcon.width ??
																"100%"
															}
															alt=""
														/>
													)}
													<Title
														headingLevel="h4"
														margin={
															icon
																? `0 10px`
																: "0"
														}
														{...textPresets.size5}
													>
														{headline}
													</Title>
												</div>
											)}
											<SmallText>{infoText}</SmallText>
										</div>
									);
								},
							)}
						</div>
					</InfoBlockContainer>
				</ContentContainer>
				<div className="lg:absolute lg:inset-0">
					<CmsImage
						image={resolvedImage}
						alt={alt}
						style={{ height: "100%" }}
						className="h-full w-full object-cover"
					/>
				</div>
			</div>
		</BaseSection>
	);
};
