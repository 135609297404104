import { cva } from "class-variance-authority";
import React from "react";
import type { VariantProps } from "class-variance-authority";
import type { ComponentProps } from "react";

type ButtonProps = ComponentProps<"button"> &
	VariantProps<typeof buttonVariants>;

export const buttonVariants = cva(
	[
		"inline-flex shrink-0 select-none items-center justify-center whitespace-nowrap rounded-full leading-larger transition-colors",
		"focus-visible:!rounded-full",
		"focus:rounded-full",
		"disabled:cursor-not-allowed disabled:opacity-20",
	],
	{
		variants: {
			variant: {
				primary: [
					"h-[49px] gap-[15px] px-[21px] py-2.5 text-xl font-medium text-white",
					"focus-visible:!outline-2 focus-visible:!outline-offset-2 focus-visible:!outline-blueberry",
					"focus:outline-2 focus:outline-offset-2 focus:outline-blueberry",
				],
				secondary: [
					"h-px-35 gap-2 p-3 text-base font-regular",
					"focus-visible:!outline-1 focus-visible:!outline-black",
					"focus:outline-1 focus:outline-black",
				],
				tertiary: [
					"h-px-35 gap-1 p-3 text-base font-regular text-white",
					"focus-visible:!outline-1 focus-visible:!outline-black",
					"focus:outline-1 focus:outline-black",
				],
				ghost: [
					"h-px-35 gap-2 p-3 text-base font-regular",
					"focus-visible:!outline-1 focus-visible:!outline-black",
					"focus:outline-1 focus:outline-black",
				],
				iconOnly: [
					"h-8 w-8 gap-2 p-2",
					"focus-visible:!outline-1 focus-visible:!outline-black",
					"focus:outline-1 focus:outline-black",
				],
			},
			isActive: {
				true: [],
			},
			isLink: {
				true: ["disable-anchor-shadow-styles"],
			},
		},
		compoundVariants: [
			{
				variant: "primary",
				isActive: false,
				isLink: false,
				className: [
					"bg-blueberry",
					"enabled:hover:bg-gradient-to-l enabled:hover:from-blueberry enabled:hover:to-mint",
					"enabled:active:bg-gradient-to-l enabled:active:from-[#4E3EFF] enabled:active:to-[#5CFFE5]",
				],
			},
			{
				variant: "primary",
				isActive: false,
				isLink: true,
				className: [
					"bg-blueberry",
					"hover:bg-gradient-to-l hover:from-blueberry hover:to-mint",
					"active:bg-gradient-to-l active:from-[#4E3EFF] active:to-[#5CFFE5]",
				],
			},
			{
				variant: "primary",
				isActive: true,
				className: ["bg-gradient-to-l from-[#4E3EFF] to-[#5CFFE5]"],
			},
			{
				variant: "secondary",
				isActive: false,
				isLink: false,
				className: [
					"bg-gray-nav text-black",
					"enabled:hover:bg-black enabled:hover:text-white",
					"enabled:active:bg-mint enabled:active:text-white",
				],
			},
			{
				variant: "secondary",
				isActive: false,
				isLink: true,
				className: [
					"bg-gray-nav text-black",
					"hover:bg-black hover:text-white",
					"active:bg-mint active:text-white",
				],
			},
			{
				variant: "secondary",
				isActive: true,
				className: ["bg-mint text-white"],
			},
			{
				variant: "tertiary",
				isActive: false,
				isLink: false,
				className: [
					"bg-gradient-to-l from-blueberry to-mint",
					"enabled:hover:bg-gradient-to-l enabled:hover:from-[#4E3EFF] enabled:hover:to-[#5CFFE5]",
				],
			},
			{
				variant: "tertiary",
				isActive: false,
				isLink: true,
				className: [
					"bg-gradient-to-l from-blueberry to-mint",
					"hover:bg-gradient-to-l hover:from-[#4E3EFF] hover:to-[#5CFFE5]",
				],
			},
			{
				variant: "tertiary",
				isActive: true,
				className: ["bg-gradient-to-l from-[#4E3EFF] to-[#5CFFE5]"],
			},
			{
				variant: "ghost",
				isActive: false,
				isLink: false,
				className: [
					"enabled:hover:bg-black enabled:hover:text-white",
					"enabled:active:bg-mint enabled:active:text-white",
				],
			},
			{
				variant: "ghost",
				isActive: false,
				isLink: true,
				className: [
					"hover:bg-black hover:text-white",
					"active:bg-mint active:text-white",
				],
			},
			{
				variant: "ghost",
				isActive: true,
				className: ["bg-mint text-white"],
			},
			{
				variant: "iconOnly",
				isActive: false,
				isLink: false,
				className: [
					"enabled:hover:bg-gray-nav enabled:hover:text-black",
					"enabled:active:bg-black enabled:active:text-white",
				],
			},
			{
				variant: "iconOnly",
				isActive: false,
				isLink: true,
				className: [
					"hover:bg-gray-nav hover:text-black",
					"active:bg-black active:text-white",
				],
			},
			{
				variant: "iconOnly",
				isActive: true,
				className: ["bg-black text-white"],
			},
		],
		defaultVariants: {
			variant: "primary",
			isActive: false,
			isLink: false,
		},
	},
);

// eslint-disable-next-line react/display-name
const Button: React.FC<ButtonProps> = React.forwardRef<
	HTMLButtonElement,
	ButtonProps
>(
	(
		{ type = "button", className, variant, isActive, isLink, ...props },
		ref,
	) => {
		return (
			<button
				ref={ref}
				// eslint-disable-next-line react/button-has-type
				type={type}
				className={buttonVariants({
					variant,
					isActive,
					isLink,
					className,
				})}
				{...props}
			/>
		);
	},
);

export default Button;
