import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef } from "react";

type FilterProps = ComponentPropsWithoutRef<"button"> &
	VariantProps<typeof filterVariants>;

const filterVariants = cva(
	[
		"inline-flex h-px-35 shrink-0 select-none items-center justify-center whitespace-nowrap rounded-full px-3 py-1 text-base font-regular leading-larger transition-colors",
		"focus-visible:!rounded-full focus-visible:!outline-1 focus-visible:!outline-black",
		"focus:rounded-full focus:outline-1 focus:outline-black",
		"disabled:cursor-not-allowed disabled:opacity-20",
	],
	{
		variants: {
			isActive: {
				false: [
					"bg-gray-nav text-black",
					"enabled:hover:bg-black enabled:hover:text-white",
					"enabled:active:bg-mint enabled:active:text-black",
				],
				true: ["bg-mint text-black"],
			},
		},
		defaultVariants: {
			isActive: false,
		},
	},
);

const Filter: React.FC<FilterProps> = ({
	type = "button",
	className,
	isActive,
	...props
}) => {
	return (
		<button
			// eslint-disable-next-line react/button-has-type
			type={type}
			className={filterVariants({ isActive, className })}
			{...props}
		/>
	);
};

export default Filter;
