"use client";

import { useCallback, useRef } from "react";
import CircledArrow from "src/assets/circledArrow.svg";

type ScrollAnchorProps = {
	anchorText: string;
};

const ScrollAnchor: React.FC<ScrollAnchorProps> = ({ anchorText }) => {
	const btnRef = useRef<HTMLButtonElement>(null);

	const scrollDown = useCallback(() => {
		btnRef.current?.scrollIntoView({ behavior: "smooth" });
	}, []);

	return (
		<button
			ref={btnRef}
			type="button"
			className="mx-auto flex flex-row items-center gap-2 px-3 py-2"
			onClick={scrollDown}
		>
			<div className="text-base font-regular leading-larger">
				{anchorText}
			</div>
			<div className="shrink-0">
				<CircledArrow />
			</div>
		</button>
	);
};

export default ScrollAnchor;
