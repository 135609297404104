import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Field as FormikField, useFormikContext } from "formik";
import { useTranslations } from "next-intl";
import React from "react";
import { ContactFormCustomizationProps } from "./ContactFormSection";
import { Field } from "./Field";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";
import Button from "../../Button/Button";
import ButtonContainer from "../../Button/ButtonContainer";

const FormElement = styled.form`
	margin-top: ${dimensions.spacing.px40};
`;

const ContactFormRow = styled(Box)`
	width: 100%;
	padding-bottom: 1.5rem;

	${mediaQuery.lg} {
		padding-bottom: 0;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;

		> * {
			margin-top: ${dimensions.spacing.px10};
			width: calc(50% - 0.8rem);

			&:first-of-type:last-of-type {
				width: 100%;
			}
		}
	}
`;

type Props = {
	formName: string;
} & ContactFormCustomizationProps;

export const ContactFormUI: React.FC<Props> = ({
	formName,
	hideCompanyField,
	hidePhoneField,
	hideMessageField,
	hideNewsletterField,
	messagePlaceholder,
}) => {
	const t = useTranslations();
	const formik = useFormikContext();

	return (
		<FormElement name={formName} onSubmit={formik.handleSubmit}>
			<input type="hidden" name="form-name" value={formName} />

			<FormikField type="hidden" name="subject" />
			<FormikField type="hidden" name="page" />
			<FormikField type="hidden" name="referrerUrl" />

			<ContactFormRow>
				<Field
					label={t("contactFormLabelName")}
					name="name"
					isRequired={true}
				/>
			</ContactFormRow>
			<ContactFormRow>
				<Field
					label={t("contactFormLabelEmail")}
					name="email"
					isRequired={true}
				/>
				{hidePhoneField ? null : (
					<Field label={t("contactFormLabelPhone")} name="phone" />
				)}
			</ContactFormRow>
			{!hideCompanyField && (
				<ContactFormRow>
					<Field
						label={t("contactFormLabelCompany")}
						name="company"
					/>
				</ContactFormRow>
			)}
			{hideMessageField ? null : (
				<ContactFormRow marginTop={dimensions.spacing.px20}>
					<Field
						type="textarea"
						ariaLabel={t("contactFormLabelMessage")}
						label={
							messagePlaceholder ??
							t("contactFormMessagePlaceholder")
						}
						name="message"
						isRequired={true}
					/>
				</ContactFormRow>
			)}
			<ContactFormRow marginTop={dimensions.spacing.px20}>
				<Field
					type="checkbox"
					label={t.rich("contactFormLabelDataPrivacy", {
						link: () => (
							<a
								href={t("contactFormLabelDataPrivacyUrl")}
								target="_blank"
								rel="noreferrer"
							>
								{t("contactFormLabelDataPrivacyLabel")}
							</a>
						),
					})}
					name="dataPrivacy"
					isRequired={true}
				/>
			</ContactFormRow>
			{hideNewsletterField ? null : (
				<ContactFormRow>
					<Field
						type="checkbox"
						label={t("contactFormNewsletterLabel")}
						name="newsletter"
					/>
				</ContactFormRow>
			)}
			<div className="hidden">
				<Field label={t("contactFormLabelBot")} name="url" />
				<Field type="checkbox" name="isWorkshopForm" />
			</div>
			<ButtonContainer
				align="center"
				alignSm={
					hidePhoneField && hideMessageField ? "center" : "right"
				}
				css={css`
					grid-column: 1 / -1;
				`}
			>
				<Button
					type="submit"
					isLoading={formik.isSubmitting}
					isDisabled={!formik.isValid}
				>
					{t("contactFormSubmit")}
				</Button>
			</ButtonContainer>
		</FormElement>
	);
};
