"use client";

import { cn } from "@peerigon/pupper/tailwind";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useState } from "react";
import Loading from "src/assets/loading.svg";
import XIcon from "src/assets/x-icon.svg";

type PolicyDialogProps = {
	label: string;
	url: string;
};

const PolicyDialog: React.FC<PolicyDialogProps> = ({ label, url }) => {
	const [isLoadingIframe, setIsLoadingIframe] = useState(true);

	return (
		<DialogPrimitive.Root modal={true}>
			<DialogPrimitive.Trigger asChild>
				<button
					type="button"
					className="inline-block w-fit whitespace-nowrap text-base font-light leading-larger hover:underline focus:underline"
				>
					{label}
				</button>
			</DialogPrimitive.Trigger>
			<DialogPrimitive.Portal>
				<DialogPrimitive.Overlay className="fixed inset-0 z-header bg-black/50 backdrop-blur-sm">
					<DialogPrimitive.Content
						className={cn(
							"fixed left-1/2 top-1/2 z-header h-full max-h-[85vh] w-[95vw] max-w-[80rem] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg border border-gray-nav bg-white shadow-popup focus:!shadow-popup",
							"animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 md:zoom-in-90 data-[state=open]:md:slide-in-from-bottom-0",
						)}
					>
						<div className="sr-only">
							<DialogPrimitive.Title>
								{label}
							</DialogPrimitive.Title>
							<DialogPrimitive.Description>
								{`peerigon.com ${label}`}
							</DialogPrimitive.Description>
						</div>
						<div className="relative h-full w-full overflow-y-auto">
							<iframe
								src={url}
								title={label}
								width="100%"
								height="100%"
								style={{ opacity: isLoadingIframe ? 0 : 1 }}
								onLoad={() => setIsLoadingIframe(false)}
							/>
							<div
								className={cn(
									"absolute left-0 top-0 flex h-full w-full items-center justify-center",
									{ hidden: !isLoadingIframe },
								)}
							>
								<Loading />
							</div>
						</div>

						<DialogPrimitive.Close
							className={cn(
								"absolute right-[2rem] top-[1rem] inline-flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-full p-2 text-black transition-colors",
								"focus-visible:!rounded-full focus-visible:!outline-1 focus-visible:!outline-black",
								"focus:rounded-full focus:outline-1 focus:outline-black",
								"enabled:hover:bg-gray-nav enabled:hover:text-black",
								"enabled:active:bg-black enabled:active:text-white",
								"disabled:cursor-not-allowed disabled:opacity-20",
							)}
						>
							<XIcon className="h-4 w-4" />
						</DialogPrimitive.Close>
					</DialogPrimitive.Content>
				</DialogPrimitive.Overlay>
			</DialogPrimitive.Portal>
		</DialogPrimitive.Root>
	);
};

export default PolicyDialog;
