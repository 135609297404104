import { css } from "@emotion/react";
import { FC, ReactNode, useLayoutEffect, useState } from "react";
import { highlight } from "src/components/sections/MarkdownSection/shared";
import { fonts } from "src/styles/variables";

// Line Numbers https://github.com/shikijs/shiki/issues/3#issuecomment-830564854
const styleCodeSyntax = css`
	line-height: ${fonts.lineHeight.large2};

	pre {
		padding: 2rem;
		overflow-x: scroll;
	}

	code {
		font-family: ${fonts.mono};
		/* forgive the !important, in this case I don't see a good way out of it, because we're patching react-syntax-highlighter's styles */
		font-size: ${fonts.fontSize.px16} !important;

		counter-reset: step;
		counter-increment: step 0;
	}

	code .line::before {
		content: counter(step);
		counter-increment: step;
		width: 1rem;
		margin-right: 1.5rem;
		display: inline-block;
		text-align: right;
		color: currentColor;
	}
`;

export const Code: FC<{
	code: (string | Array<string>) & ReactNode;
	language: string;
}> = ({ code, language }) => {
	const [html, setHtml] = useState<string | null>(null);

	useLayoutEffect(() => {
		void highlight(code, language).then(setHtml);
	}, [code, language]);

	return html ? (
		// eslint-disable-next-line react/no-danger
		<div css={styleCodeSyntax} dangerouslySetInnerHTML={{ __html: html }} />
	) : (
		<div className="shimmer relative h-20 overflow-hidden bg-[rgb(46,_52,_64)]" />
	);
};
