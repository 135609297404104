import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ColorThemeEnum, Spacing } from "../../../__generated__/graphql";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions, getColorTheme, sizeMap } from "../../../styles/variables";

export const styles = (
	colorThemeEnum: ColorThemeEnum,
	sectionSpacing: Spacing,
) => css`
	--slantOffset: 3vw;

	background-color: ${getColorTheme(colorThemeEnum).bg};
	color: ${getColorTheme(colorThemeEnum).fg};
	padding-bottom: var(--slantOffset);
	padding-top: 0;
	margin-top: ${sizeMap[sectionSpacing]};

	/* if we have spacing, we slant the top as well, otherwise not */
	${sectionSpacing === Spacing.xxxSmall
		? css`
				clip-path: polygon(
					0% 0,
					0% 100%,
					100% calc(100% - var(--slantOffset)),
					100% 0%
				);
			`
		: css`
				clip-path: polygon(
					0% var(--slantOffset),
					0% 100%,
					100% calc(100% - var(--slantOffset)),
					100% 0%
				);
			`}
`;

export const ImageContainer = styled.div`
	height: 30vh;
	min-height: ${dimensions.widths.px320};
	max-height: ${dimensions.widths.px900};
	overflow: hidden;

	clip-path: polygon(
		0% 0%,
		0% calc(100% - var(--slantOffset)),
		100% 100%,
		100% 0%
	);

	${mediaQuery.md} {
		height: 50vh;
	}
`;
