"use client";

import slugify from "@sindresorhus/slugify";
import { useTranslations } from "next-intl";
import { useMedia } from "react-use";
import { getFragmentData, graphql } from "src/__generated__";
import { Spacing } from "src/__generated__/graphql";
import CmsImage, { CmsImageFields } from "src/components/Image/CmsImage";
import Label from "src/components/Label/Label";
import BaseSection from "src/components/sections/BaseSection";
import { CaseStudyListElementFragment } from "src/components/sections/CaseStudyListSection/CaseStudyListSection";
import ActionButtons from "src/components/sections/CaseStudyPageHeaderSection/ActionButtons";
import { Markdown } from "src/components/sections/MarkdownSection/MarkdownSection";
import { getImageAlt } from "src/components/server.helpers";
import { ContentContainerTw } from "src/components/styled/ContentContainer";
import { breakpoints } from "src/styles/variables";
import { Hx } from "uberschrift";
import type { FragmentType } from "src/__generated__";

export const CaseStudyPageHeaderSectionFragment = graphql(`
	fragment CaseStudyPageHeaderSectionItem on CaseStudyPageHeaderSection {
		id
		spacingTop
		caseStudyElement {
			...CaseStudyListElementItem
		}
		spacingBottom
	}
`);

export const CaseStudyPageHeaderSection: React.FC<{
	section: FragmentType<typeof CaseStudyPageHeaderSectionFragment>;
}> = ({ section }) => {
	const t = useTranslations();
	const isMinMd = useMedia(`(min-width: ${breakpoints.md}px)`, false);

	const { spacingTop, caseStudyElement, spacingBottom } = getFragmentData(
		CaseStudyPageHeaderSectionFragment,
		section,
	);

	if (!caseStudyElement) return null;

	const { name, headline, description, tags, image, images, websiteUrl } =
		getFragmentData(CaseStudyListElementFragment, caseStudyElement);
	const slug = slugify(name);
	const imagesData = images.map((d) => getFragmentData(CmsImageFields, d));
	const imageData =
		getFragmentData(CmsImageFields, image) ?? imagesData.at(0);

	return (
		<BaseSection
			paddingTop={isMinMd ? spacingTop : Spacing.medium}
			paddingBottom={spacingBottom}
		>
			<ContentContainerTw>
				<div className="flex flex-col gap-10 md:gap-20">
					<ActionButtons
						websiteUrl={websiteUrl ?? undefined}
						slug={slug}
						className="justify-end md:hidden"
					/>

					<div className="flex flex-col gap-10 md:gap-5">
						<div className="flex flex-col gap-3 md:gap-5">
							<div className="flex flex-col gap-3 md:gap-2">
								<div className="text-size-6">
									{t("successStory")}
								</div>

								<Hx className="m-0 text-[clamp(3.2rem,3vw_+_1rem,4.4rem)] font-bold leading-regular-3 sm:w-3/4 md:leading-regular-1 lg:break-normal">
									{name}
								</Hx>

								<div className="text-xl font-regular leading-larger">
									{headline}
								</div>
							</div>

							{isMinMd || tags.length ? (
								<div className="flex gap-5">
									{tags.length ? (
										<div className="flex flex-1 flex-row flex-wrap items-center gap-2">
											{tags.map((tag) => (
												<Label key={tag}>{tag}</Label>
											))}
										</div>
									) : null}
									<ActionButtons
										websiteUrl={websiteUrl ?? undefined}
										slug={slug}
										className="ml-auto hidden md:flex"
									/>
								</div>
							) : null}
						</div>

						{imageData ? (
							<CmsImage
								image={imageData}
								alt={getImageAlt(imageData)}
								className="h-full w-full rounded-lg bg-gray-100 object-cover md:rounded-xl xl:rounded-[2rem]"
							/>
						) : null}
					</div>

					{description ? (
						<div className="mx-auto max-w-[80rem] [&_div]:m-0 [&_p]:text-center [&_p]:text-base [&_p]:font-light [&_p]:leading-larger [&_p]:md:text-xl">
							<Markdown source={description} />
						</div>
					) : null}
				</div>
			</ContentContainerTw>
		</BaseSection>
	);
};
