import styled from "@emotion/styled";
import { disableAnchorShadowStyles, mediaQuery } from "../../../styles/mixins";
import { colors, fonts, shadows, textPresets } from "../../../styles/variables";
import Link from "../../Link/Link";

export const StyledDurationContainer = styled.div`
	--sizeXS: 100px;
	--sizeMD: 110px;
	--sizeLG: 140px;

	display: flex;
	justify-content: center;
	flex-direction: column;
	background: white;
	filter: drop-shadow(${shadows.narrow});
	border-radius: 10px;
	width: var(--sizeXS);
	height: var(--sizeXS);
	margin-top: calc(var(--sizeXS) * -1 + 25px);
	margin-bottom: 20px;
	position: relative;

	${mediaQuery.md} {
		position: absolute;
		bottom: -35px;
		width: var(--sizeMD);
		height: var(--sizeMD);
	}
	${mediaQuery.lg} {
		width: var(--sizeLG);
		height: var(--sizeLG);
	}
`;

export const StyledDurationAmount = styled.p`
	font-weight: ${fonts.fontWeight.bold};
	line-height: ${fonts.lineHeight.regular1};
	text-align: center;
	margin-bottom: 0.5rem;
	font-size: ${fonts.fontSize.px28};

	${mediaQuery.lg} {
		font-size: ${fonts.fontSize.px44};
	}
`;
export const StyledDurationUnit = styled.p`
	line-height: ${fonts.lineHeight.regular2};
	letter-spacing: ${fonts.letterSpacing.wide};
	text-align: center;
	text-transform: uppercase;
	font-size: ${fonts.fontSize.px16};
	color: ${colors.gray.dark};
	margin-bottom: 0;

	${mediaQuery.lg} {
		font-size: ${fonts.fontSize.px20};
	}
`;

export const StyledInfoItemTitle = styled.p`
	font-size: ${textPresets.size6.fontSize};
	line-height: ${textPresets.size6.lineHeight};
	text-transform: ${textPresets.size6.textTransform};
	letter-spacing: ${fonts.letterSpacing.wide};
	font-weight: ${fonts.fontWeight.bold};
	margin-bottom: 5px;
	${mediaQuery.lg} {
		font-size: ${textPresets.size6.fontSizeLg};
	}
`;

export const ShadowContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: white;
	margin-bottom: 35px;
	position: relative;

	${mediaQuery.md} {
		filter: drop-shadow(${shadows.narrow});
		flex-direction: row;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	height: 490px;
	width: 100%;
	margin-bottom: 50px;
	.graphcms-image-wrapper {
		height: 100%;
		margin: 0 -5vw;
		${mediaQuery.md} {
			margin: 0;
		}
	}
	${mediaQuery.md} {
		height: auto;
		width: 400px;
		max-width: 40%;
		margin-bottom: 0;
		.graphcms-image-wrapper {
			width: 400px;
			max-width: 100%;
		}
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-left: -5vw;
	grid-area: button;
	margin-top: 30px;
	${mediaQuery.md} {
		width: auto;
		margin: 0;
	}
`;

export const StyledGrid = styled.div`
	display: grid;
	grid-template-areas: "add-on" "title" "text" "divider" "info-items" "button";
	grid-template-columns: minmax(0, 100%);

	${mediaQuery.md} {
		grid-template-rows: max-content;
		margin-top: 40px;
		margin-left: 30px;
		margin-right: 30px;
		margin-bottom: 0;
		grid-template-areas: "add-on" "title" "text" "button" "divider" "info-items";
	}
	${mediaQuery.lg} {
		grid-template-rows: unset;
		margin-top: 60px;
		margin-left: 50px;
		margin-right: 50px;
		margin-bottom: 20px;
	}
`;

export const StyledLink = styled(Link)`
	width: 100%;
	${disableAnchorShadowStyles}
	${mediaQuery.md} {
		width: auto;
	}
`;

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background: ${colors.gray.line};
	margin: 20px 0;
	${mediaQuery.lg} {
		margin: 30px 0;
	}
`;
