import AirDropIcon from "src/assets/air-drop.svg";

type ShareToastProps = {
	label: string;
};

const ShareToast: React.FC<ShareToastProps> = ({ label }) => {
	return (
		<div className="mx-auto flex max-w-fit items-center gap-3 rounded-full border-2 border-peppermint bg-foam py-2 pl-2 pr-5 shadow-card">
			<AirDropIcon />
			<div className="text-size-8 whitespace-nowrap font-medium">
				{label}
			</div>
		</div>
	);
};

export default ShareToast;
