import { useState } from "react";
import LanguageSwitch from "src/components/LanguageSwitch/LanguageSwitch";
import HomeLink from "src/components/Navigation/HomeLink";
import MobileMenuButton from "src/components/Navigation/MobileMenuButton";
import MobileMenuDialog from "src/components/Navigation/MobileMenuDialog";
import MobileNavLink from "src/components/Navigation/MobileNavLink";
import { getActiveTopLevelRouteName } from "src/components/helpers";
import type { HeaderFieldsFragment, PageType } from "src/__generated__/graphql";
import type { NavLink } from "src/components/Navigation/Navigation";
import type { Locale } from "src/i18n/routing";

type MobileNavigationProps = {
	mainNavLinks: Array<NavLink>;
	secondaryNavLinks: Array<NavLink>;
	items: HeaderFieldsFragment["items"];
	pathname: string;
	initialMobileMenuVisible?: boolean;
	disableLanguageSwitch?: boolean;
	currentPageType?: PageType;
	onChangeLocale: (locale: Locale) => void;
};

const MobileNavigation: React.FC<MobileNavigationProps> = ({
	mainNavLinks,
	secondaryNavLinks,
	items,
	pathname,
	initialMobileMenuVisible = false,
	disableLanguageSwitch,
	currentPageType,
	onChangeLocale,
}) => {
	const [isMenuActive, setIsMenuActive] = useState(initialMobileMenuVisible);
	const [selectedLinkRouteName, setSelectedLinkRouteName] = useState<
		string | undefined
	>(() => getActiveTopLevelRouteName(items, pathname));

	const toggleMenu = () => {
		setIsMenuActive(!isMenuActive);
	};

	return (
		<div className="block md:hidden">
			<div className="flex flex-row items-center justify-between gap-1">
				<div className="flex-shrink-0">
					<HomeLink />
				</div>
				<div className="flex-shrink-0">
					<MobileMenuButton
						isActive={isMenuActive}
						toggleMenu={toggleMenu}
					/>
					<MobileMenuDialog
						isActive={isMenuActive}
						toggleMenu={toggleMenu}
					>
						<div className="grid min-h-full grid-rows-[1fr_auto]">
							<nav className="flex flex-col items-center justify-center gap-5 py-5">
								{mainNavLinks.map((link) => (
									<MobileNavLink
										key={link.id}
										link={link}
										currentPageType={currentPageType}
										selectedLinkRouteName={
											selectedLinkRouteName
										}
										setSelectedLinkRouteName={(
											routeName,
										) => {
											if (
												routeName ===
												selectedLinkRouteName
											) {
												setIsMenuActive(false);
											}
											setSelectedLinkRouteName(routeName);
										}}
									/>
								))}
							</nav>
							<div className="flex flex-shrink-0 flex-row items-center justify-center gap-1 pb-10 pt-6">
								{secondaryNavLinks.map((link) => (
									<MobileNavLink
										key={link.id}
										link={link}
										size="small"
										selectedLinkRouteName={
											selectedLinkRouteName
										}
										setSelectedLinkRouteName={(
											routeName,
										) => {
											if (
												routeName ===
												selectedLinkRouteName
											) {
												setIsMenuActive(false);
											}
											setSelectedLinkRouteName(routeName);
										}}
									/>
								))}
								<LanguageSwitch
									disableSwitch={disableLanguageSwitch}
									onChangeLocale={onChangeLocale}
								/>
							</div>
						</div>
					</MobileMenuDialog>
				</div>
			</div>
		</div>
	);
};

export default MobileNavigation;
