"use client";

import { Box, Flex, Grid, Text, TextProps } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useTranslations } from "next-intl";
import React from "react";
import { useBoolean } from "react-use";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { ContactFormSectionItemFragment } from "src/__generated__/graphql";
import ClipPathContainer from "./ClipPathContainer";
import ContactFormContainer from "./ContactFormContainer";
import ContactFormSectionContent from "./ContactFormSectionContent";
import ContactTextWrapper from "./ContactTextWrapper";
import DiagonalBackground from "./DiagonalBackground";
import ImageContainer from "./ImageContainer";
import StyledLink from "./StyledLink";
import Uppercase from "./Uppercase";
import { mediaQuery } from "../../../styles/mixins";
import { colors, dimensions, textPresets } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import Title from "../../Title/Title";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const ContactFormSectionFragment = graphql(`
	fragment ContactFormSectionItem on ContactFormSection {
		id
		anchor
		emailAddress
		phoneNumber
		messagePlaceholder
		description
		image {
			...CmsImageFields
		}
		disableForm
		hideCompanyField
		isWorkshopForm
		title
		spacingTop
		spacingBottom
	}
`);

const styleDescription = css`
	margin-top: ${dimensions.spacing.px30};
	margin-bottom: ${dimensions.spacing.px30};

	${mediaQuery.lg} {
		margin-top: ${dimensions.spacing.px40};
	}
`;

export type ContactFormCustomizationProps = {
	hideCompanyField?: ContactFormSectionItemFragment["hideCompanyField"];
	hidePhoneField?: boolean;
	hideMessageField?: boolean;
	hideNewsletterField?: boolean;
	isWorkshopForm?: ContactFormSectionItemFragment["isWorkshopForm"];
	messagePlaceholder?: ContactFormSectionItemFragment["messagePlaceholder"];
};

export const ContactFormSection: React.FC<{
	section: FragmentType<typeof ContactFormSectionFragment>;
}> = ({ section }) => {
	const [showSuccess, toggleShowSuccess] = useBoolean(false);
	const {
		spacingTop,
		spacingBottom,
		title,
		description,
		emailAddress,
		phoneNumber,
		image,
		disableForm,
		hideCompanyField,
		isWorkshopForm,
		messagePlaceholder,
		anchor,
	} = getFragmentData(ContactFormSectionFragment, section);
	const t = useTranslations();
	const showForm = !disableForm;
	const formName = `Contact Form (${emailAddress})`;
	const imageData = getFragmentData(CmsImageFields, image);

	return (
		<BaseSection
			paddingTop={spacingTop}
			paddingBottom={spacingBottom}
			hideOverflow
		>
			<Anchor id={anchor} />
			<ImageContainer>
				<CmsImage image={imageData} />
			</ImageContainer>
			<ClipPathContainer>
				<DiagonalBackground>
					<ContactFormSectionContent>
						<ContactTextWrapper>
							<Title headingLevel="h2">{title}</Title>
							<Markdown
								source={description}
								css={styleDescription}
							/>
							<Grid
								gridColumn={2}
								templateColumns="auto 1fr"
								columnGap={[5, 20]}
								rowGap="15px"
							>
								<Uppercase>
									{t("contactFormSectionEmail")}
								</Uppercase>
								<Flex lineHeight={1}>
									<StyledLink to={`mailto:${emailAddress}`}>
										{emailAddress}
									</StyledLink>
								</Flex>
								<Uppercase>
									{t("contactFormSectionTelephone")}
								</Uppercase>
								<Flex lineHeight={1}>
									<StyledLink to={`tel:${phoneNumber}`}>
										{phoneNumber}
									</StyledLink>
								</Flex>
							</Grid>
							{showForm && showSuccess && (
								<Box
									backgroundColor={colors.sea}
									marginTop={dimensions.spacing.px40}
									paddingX="1.4rem"
									paddingY="2.1rem"
								>
									<Text
										mb="0"
										{...(textPresets.size7 as TextProps)}
									>
										{t("contactFormSuccessMessage")}
										<br />
										<strong>
											{t.rich(
												"contactFormSuccessCTAText",
												{
													button: (chunks) => (
														<button
															type="button"
															onClick={(ev) => {
																ev.preventDefault();
																toggleShowSuccess(
																	false,
																);
															}}
														>
															{chunks}
														</button>
													),
												},
											)}
										</strong>
									</Text>
								</Box>
							)}

							{showForm && !showSuccess && (
								<ContactFormContainer
									formName={formName}
									onSuccess={() => toggleShowSuccess(true)}
									hideCompanyField={hideCompanyField}
									isWorkshopForm={isWorkshopForm}
									messagePlaceholder={messagePlaceholder}
								/>
							)}
						</ContactTextWrapper>
					</ContactFormSectionContent>
				</DiagonalBackground>
			</ClipPathContainer>
		</BaseSection>
	);
};
