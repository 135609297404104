"use client";

import slugify from "@sindresorhus/slugify";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useTranslations } from "next-intl";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import Button from "src/components/ButtonNew/Button";
import Card from "src/components/Card/Card";
import { CmsImageFields } from "src/components/Image/CmsImage";
import DesktopFiltersBar from "src/components/sections/CaseStudyListSection/DesktopFiltersBar";
import MobileFiltersPopover from "src/components/sections/CaseStudyListSection/MobileFiltersPopover";
import NoResults from "src/components/sections/CaseStudyListSection/NoResults";
import Anchor from "../../Anchor/Anchor";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const CaseStudyListSectionFragment = graphql(`
	fragment CaseStudyListSectionItem on CaseStudyListSection {
		id
		anchor
		filters
		caseStudyListElements {
			...CaseStudyListElementItem
		}
		spacingTop
		spacingBottom
	}
`);

export const CaseStudyListElementFragment = graphql(`
	fragment CaseStudyListElementItem on OrderedListElement {
		id
		name
		headline
		description
		tags
		websiteUrl
		image {
			...CmsImageFields
		}
		images {
			...CmsImageFields
		}
		detailPage {
			routeName
		}
		detailPageLabel
	}
`);

const CASE_STUDIES_COUNT_TO_SHOW = 10;

export const CaseStudyListSection: FC<{
	section: FragmentType<typeof CaseStudyListSectionFragment>;
}> = ({ section }) => {
	const t = useTranslations();
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { replace } = useRouter();

	const {
		anchor,
		filters,
		caseStudyListElements,
		spacingTop,
		spacingBottom,
	} = getFragmentData(CaseStudyListSectionFragment, section);

	const caseStudyListElementsData = caseStudyListElements.map((d) =>
		getFragmentData(CaseStudyListElementFragment, d),
	);

	const filteredCaseStudyListElements = useMemo(() => {
		const currentFilters = searchParams.getAll("filter");
		return caseStudyListElementsData.filter(({ tags }) => {
			const match = tags.some((tag) => currentFilters.includes(tag));
			return currentFilters.length === 0 || match;
		});
	}, [caseStudyListElementsData, searchParams]);

	const slugs = useMemo(
		() => caseStudyListElementsData.map(({ name }) => `#${slugify(name)}`),
		[caseStudyListElementsData],
	);
	const cardToHighlightSlug = searchParams.get("highlight") ?? "";
	const highlightIndex = slugs.findIndex(
		(slug) => slug === `#${cardToHighlightSlug}`,
	);
	const initialShowMore = highlightIndex >= CASE_STUDIES_COUNT_TO_SHOW;

	const [isShowingMore, setIsShowingMore] = useState(initialShowMore);

	useEffect(() => {
		const anchor = document.querySelector(
			`div[data-anchor]#${searchParams.get("highlight")}`,
		);
		anchor?.scrollIntoView({ behavior: "smooth" });
		const params = new URLSearchParams(searchParams.toString());
		params.delete("highlight");
		replace(`${pathname}?${params.toString()}`, { scroll: false });
	}, [pathname, replace, searchParams]);

	const toggleShowMore = useCallback(() => {
		setIsShowingMore((s) => !s);
	}, [setIsShowingMore]);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<ContentContainer>
				<div className="mb-5 flex flex-col gap-5 md:mb-10 md:gap-10">
					{filters.length ? (
						<>
							<MobileFiltersPopover filters={filters} />
							<DesktopFiltersBar filters={filters} />
						</>
					) : null}
					{filteredCaseStudyListElements.length === 0 ? (
						<NoResults />
					) : (
						<div className="flex flex-col gap-1">
							<div className="flex flex-col gap-4">
								{filteredCaseStudyListElements
									.slice(
										0,
										isShowingMore
											? filteredCaseStudyListElements.length
											: CASE_STUDIES_COUNT_TO_SHOW,
									)
									.map((caseStudy) => {
										const imagesData = caseStudy.images.map(
											(d) =>
												getFragmentData(
													CmsImageFields,
													d,
												),
										);
										const labels = caseStudy.tags.map(
											(tag) => ({
												name: tag,
												isActive: searchParams
													.getAll("filter")
													.includes(tag),
											}),
										);
										const action = caseStudy.detailPage
											? {
													label:
														caseStudy.detailPageLabel ??
														t("viewProject"),
													url: caseStudy.detailPage
														.routeName,
												}
											: undefined;
										return (
											<Card
												key={caseStudy.id}
												name={caseStudy.name}
												headline={caseStudy.headline}
												labels={labels}
												description={
													caseStudy.description ??
													undefined
												}
												action={action}
												websiteUrl={
													caseStudy.websiteUrl ??
													undefined
												}
												imagesData={imagesData}
											/>
										);
									})}
							</div>
							{filteredCaseStudyListElements.length >
								CASE_STUDIES_COUNT_TO_SHOW && !isShowingMore ? (
								<div className="flex justify-center pb-20 pt-10">
									<Button onClick={toggleShowMore}>
										{t("showMoreSuccessStories")}
									</Button>
								</div>
							) : null}
						</div>
					)}
				</div>
			</ContentContainer>
		</BaseSection>
	);
};
