"use client";

import { SimpleGrid } from "@chakra-ui/react";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { CmsImageFields } from "src/components/Image/CmsImage";
import ContainedImage from "./ContainedImage";
import { disableAnchorShadowStyles, mediaQuery } from "../../../styles/mixins";
import { getColorTheme } from "../../../styles/variables";
import Link from "../../Link/Link";
import TitleDescription from "../../TitleDescription/TitleDescription";
import { getImageAlt } from "../../server.helpers";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

const ColoredBackground = styled("div", {
	shouldForwardProp: isPropValid,
})<{ background: string }>(
	({ background }) =>
		background !== "white" && {
			background,
			padding: "40px 0",
			clipPath: `polygon(0 5%, 100% 0, 100% 100%, 0% 97%)`,
			[mediaQuery.md]: {
				padding: "90px 0 120px 0",
				clipPath: `polygon(0 8%, 100% 0, 100% 100%, 0% 94%)`,
			},
		},
);

export const LogoWallSectionFieldsFragment = graphql(`
	fragment LogoWallSectionItem on LogoWallSection {
		__typename
		id
		logoWallTitle: title
		descriptionMd
		spacingTop
		spacingBottom
		colorTheme
		crossLinks {
			linkToPage {
				... on Page {
					id
					routeName
				}
				... on BlogPostPage {
					id
					routeName
				}
			}
			externalLink
			image {
				...CmsImageFields
			}
		}
	}
`);

export const LogoWallSection: React.FC<{
	section: FragmentType<typeof LogoWallSectionFieldsFragment>;
}> = ({ section }) => {
	const {
		logoWallTitle,
		descriptionMd,
		colorTheme,
		crossLinks,
		spacingTop,
		spacingBottom,
	} = getFragmentData(LogoWallSectionFieldsFragment, section);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<ColoredBackground background={getColorTheme(colorTheme).bg}>
				<ContentContainer>
					<TitleDescription
						title={logoWallTitle}
						description={descriptionMd}
						color={getColorTheme(colorTheme).fg}
					/>
					<SimpleGrid
						columns={[3, 3, 4]}
						spacingX={["40px", "40px", "60px", "100px"]}
						spacingY={["20px", "40px"]}
						gridAutoRows={["45px", "45px", "55px", "70px"]}
					>
						{crossLinks.map(
							({ image, externalLink, linkToPage }) => {
								if (!image) {
									return null;
								}

								const resolvedImage = getFragmentData(
									CmsImageFields,
									image,
								);

								const usedLink =
									linkToPage?.routeName ?? externalLink;

								if (usedLink) {
									return (
										<Link
											key={resolvedImage.handle}
											title={getImageAlt(resolvedImage)}
											to={usedLink}
											css={css`
												${disableAnchorShadowStyles}
											`}
										>
											<ContainedImage
												icon={resolvedImage}
											/>
										</Link>
									);
								}

								return (
									<ContainedImage
										icon={resolvedImage}
										key={resolvedImage.handle}
									/>
								);
							},
						)}
					</SimpleGrid>
				</ContentContainer>
			</ColoredBackground>
		</BaseSection>
	);
};
