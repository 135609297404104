import { cn } from "@peerigon/pupper/tailwind";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { forwardRef } from "react";
import type { ComponentProps, ElementRef } from "react";

export const AgendaTabs = TabsPrimitive.Root;

type AgendaTabsNavProps = ComponentProps<"button">;

// eslint-disable-next-line react/display-name
export const AgendaTabsNav: React.FC<AgendaTabsNavProps> = forwardRef<
	ElementRef<typeof TabsPrimitive.Trigger>,
	AgendaTabsNavProps
>(({ className, ...props }, ref) => {
	return (
		<button
			ref={ref}
			type="button"
			className={cn(
				"hidden h-8 w-8 shrink-0 select-none items-center justify-center rounded-full bg-transparent p-2 transition-colors",
				"outline-1 outline-black focus-visible:!rounded-full focus-visible:!outline-1 focus-visible:!outline-black",
				"enabled:hover:bg-gray-nav",
				"enabled:active:bg-black enabled:active:text-white",
				"disabled:cursor-not-allowed disabled:opacity-20",
				className,
			)}
			{...props}
		/>
	);
});

type AgendaTabsScrollAreaProps = ComponentProps<
	typeof ScrollAreaPrimitive.Root
>;

export const AgendaTabsScrollArea: React.FC<AgendaTabsScrollAreaProps> =
	// eslint-disable-next-line react/display-name
	forwardRef<
		ElementRef<typeof ScrollAreaPrimitive.Root>,
		AgendaTabsScrollAreaProps
	>(({ className, children, ...props }, ref) => {
		return (
			<ScrollAreaPrimitive.Root
				ref={ref}
				className={cn(
					"relative flex-1 overflow-hidden rounded-full shadow-card",
					className,
				)}
				{...props}
			>
				<ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">
					{children}
				</ScrollAreaPrimitive.Viewport>
				<ScrollAreaPrimitive.Scrollbar orientation="horizontal">
					<ScrollAreaPrimitive.Thumb />
				</ScrollAreaPrimitive.Scrollbar>
			</ScrollAreaPrimitive.Root>
		);
	});

type AgendaTabsListProps = ComponentProps<typeof TabsPrimitive.List>;

// eslint-disable-next-line react/display-name
export const AgendaTabsList: React.FC<AgendaTabsListProps> = forwardRef<
	ElementRef<typeof TabsPrimitive.List>,
	AgendaTabsListProps
>(({ className, ...props }, ref) => {
	return (
		<TabsPrimitive.List
			ref={ref}
			className={cn("flex h-10 gap-1 bg-white p-1", className)}
			{...props}
		/>
	);
});

type AgendaTabsTriggerProps = ComponentProps<typeof TabsPrimitive.Trigger>;

// eslint-disable-next-line react/display-name
export const AgendaTabsTrigger: React.FC<AgendaTabsTriggerProps> = forwardRef<
	ElementRef<typeof TabsPrimitive.Trigger>,
	AgendaTabsTriggerProps
>(({ className, ...props }, ref) => {
	return (
		<TabsPrimitive.Trigger
			ref={ref}
			className={cn(
				"flex h-8 min-w-fit flex-1 cursor-pointer select-none scroll-ml-1 items-center justify-center whitespace-nowrap rounded-full bg-transparent px-3 text-base font-regular leading-larger transition-colors",
				"data-[state=active]:bg-black data-[state=active]:text-white",
				"data-[state=active]:hover:bg-black data-[state=active]:hover:text-white",
				"outline-1 outline-offset-2 outline-black focus-visible:!rounded-full focus-visible:!outline-1 focus-visible:!outline-offset-2 focus-visible:!outline-black",
				"focus:rounded-full focus:outline-1 focus:outline-black",
				"enabled:hover:bg-gray-nav",
				"enabled:active:bg-black enabled:active:text-white",
				className,
			)}
			{...props}
		/>
	);
});

type AgendaTabsContentProps = ComponentProps<typeof TabsPrimitive.Content>;

// eslint-disable-next-line react/display-name
export const AgendaTabsContent: React.FC<AgendaTabsContentProps> = forwardRef<
	ElementRef<typeof TabsPrimitive.Content>,
	AgendaTabsContentProps
>(({ className, ...props }, ref) => {
	return (
		<TabsPrimitive.Content
			ref={ref}
			className={cn(
				"flex grow flex-col gap-2 pt-3 outline-none focus-visible:!outline-none",
				className,
			)}
			{...props}
		/>
	);
});
