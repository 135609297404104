"use client";

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useTranslations } from "next-intl";
import { useRef } from "react";
import XIcon from "src/assets/x-icon.svg";
import Button from "src/components/ButtonNew/Button";
import Filter from "src/components/Filter/Filter";
import { useFilters } from "src/components/sections/CaseStudyListSection/CaseStudyListSection.hooks";
import Sliders from "../../../assets/sliders.svg";

type MobileFiltersPopoverProps = {
	filters: Array<string>;
};

const MobileFiltersPopover: React.FC<MobileFiltersPopoverProps> = ({
	filters,
}) => {
	const t = useTranslations();
	const btnRef = useRef<HTMLButtonElement>(null);
	const [selectedFilters, { handleSetFilter, handleClearFilters }] =
		useFilters();

	return (
		<div className="block md:hidden">
			<Popover className="relative">
				<div className="flex items-center gap-1">
					<PopoverButton
						as={Button}
						ref={btnRef}
						variant="ghost"
						className="flex items-center gap-2 self-start"
						onClick={() => {
							const elementPosition =
								btnRef.current?.getBoundingClientRect().top ??
								0;
							const offset =
								elementPosition + window.scrollY - 100;
							window.scrollTo({
								top: offset,
							});
						}}
					>
						<Sliders />
						<div className="text-base font-regular leading-larger">
							{t("filters")}
						</div>
					</PopoverButton>
					{selectedFilters.length ? (
						<div className="flex h-7 min-w-7 items-center justify-center rounded-full bg-mint p-2 text-base font-regular leading-medium">
							{selectedFilters.length}
						</div>
					) : null}
				</div>
				<PopoverPanel
					anchor="bottom"
					transition
					className="bottom-0 left-0 right-0 z-header origin-top bg-white transition duration-200 ease-out [--anchor-gap:-55px] data-[closed]:scale-95 data-[closed]:opacity-0"
					modal={true}
					focus={true}
				>
					{({ close }) => (
						<div className="grid h-full max-h-full grid-rows-[auto_1fr_auto]">
							<div className="flex items-center gap-1 px-5 pb-3 pt-5">
								<Button
									variant="ghost"
									className="flex items-center gap-2 self-start"
									onClick={() => close()}
								>
									<XIcon />
									<div className="text-base font-regular leading-larger">
										{t("filters")}
									</div>
								</Button>
								{selectedFilters.length ? (
									<div className="flex h-7 min-w-7 items-center justify-center rounded-full bg-mint p-2 text-base font-regular leading-medium">
										{selectedFilters.length}
									</div>
								) : null}
							</div>

							<div className="overflow-auto px-5 pb-5 pt-2">
								<div className="flex flex-wrap gap-3">
									{filters.map((filter) => (
										<Filter
											key={filter}
											isActive={selectedFilters.includes(
												filter,
											)}
											onClick={() =>
												handleSetFilter(filter)
											}
										>
											{filter}
										</Filter>
									))}
								</div>
							</div>

							<div className="flex items-center justify-between gap-3 p-6 shadow-header">
								<Button
									variant="ghost"
									disabled={selectedFilters.length === 0}
									onClick={() => {
										handleClearFilters();
										close();
									}}
								>
									<XIcon /> {t("clearFilters")}
								</Button>
								<Button
									variant="secondary"
									onClick={() => close()}
								>
									{t("viewResults")}
								</Button>
							</div>
						</div>
					)}
				</PopoverPanel>
			</Popover>
		</div>
	);
};

export default MobileFiltersPopover;
