"use client";

import { useTranslations } from "next-intl";
import Button from "src/components/ButtonNew/Button";
import { useFilters } from "src/components/sections/CaseStudyListSection/CaseStudyListSection.hooks";
import BinocularsHands from "../../../assets/binoculars-hands.svg";

const NoResults: React.FC = () => {
	const t = useTranslations();
	const [, { handleClearFilters }] = useFilters();

	return (
		<div className="flex flex-col">
			<div className="flex max-w-full items-center justify-center">
				<BinocularsHands />
			</div>
			<div className="flex flex-col items-center gap-8">
				<div className="text-[clamp(4.4rem,5vw_+_1rem,6.8rem)] font-bold leading-regular-1">
					{t("noSuccessStoriesHeader")}
				</div>
				<div className="text-center text-xl font-regular leading-larger">
					{t("noSuccessStoriesText")}
				</div>
				<Button onClick={handleClearFilters}>
					{t("resetFilters")}
				</Button>
			</div>
		</div>
	);
};

export default NoResults;
