import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { colors, fonts } from "../../styles/variables";

export const CapitalLetter = styled.span`
	font-weight: ${fonts.fontWeight.bold};
	font-size: ${fonts.fontSize.px44};
	line-height: ${fonts.lineHeight.regular1};
	color: ${colors.mint};
	${mediaQuery.md} {
		font-size: ${fonts.fontSize.px68};
	}
`;
