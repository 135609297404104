"use client";

import { cn } from "@peerigon/pupper/tailwind";
import { useTranslations } from "next-intl";
import LinkIcon from "src/assets/link.svg";
import { buttonVariants } from "src/components/ButtonNew/Button";
import Link from "src/components/Link/Link";
import ShareButton from "src/components/ShareButton/ShareButton";

type ActionButtonsProps = {
	websiteUrl?: string;
	slug: string;
	className?: string;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
	websiteUrl,
	slug,
	className,
}) => {
	const t = useTranslations();

	return (
		<div className={cn("flex flex-row items-center gap-2", className)}>
			{websiteUrl ? (
				<Link
					to={websiteUrl}
					className={buttonVariants({
						variant: "ghost",
						isLink: true,
					})}
				>
					<LinkIcon /> {t("website")}
				</Link>
			) : null}
			<ShareButton slug={slug} />
		</div>
	);
};

export default ActionButtons;
