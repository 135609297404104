"use client";

import { useTranslations } from "next-intl";
import { useMedia } from "react-use";
import MaximizeIcon from "src/assets/maximize.svg";
import NavLeftIcon from "src/assets/navLeft.svg";
import NavRightIcon from "src/assets/navRight.svg";
import XIcon from "src/assets/x-icon.svg";
import Button from "src/components/ButtonNew/Button";
import { breakpoints } from "src/styles/variables";

type CarouselNavigationProps = {
	isMaximized?: boolean;
	canScrollPrev: boolean;
	canScrollNext: boolean;
	onScrollPrev: VoidFunction;
	onScrollNext: VoidFunction;
	onMaximize?: VoidFunction;
};

const CarouselNavigation: React.FC<CarouselNavigationProps> = ({
	isMaximized = false,
	canScrollPrev,
	canScrollNext,
	onScrollPrev,
	onScrollNext,
	onMaximize,
}) => {
	const t = useTranslations();
	const isMinMd = useMedia(`(min-width: ${breakpoints.md}px)`, false);

	if (!isMinMd && !canScrollNext && !canScrollPrev) return null;

	return (
		<div className="flex items-center gap-1 rounded-full bg-white p-1 shadow-card">
			{isMinMd ? (
				<Button
					variant="iconOnly"
					aria-label={
						isMaximized ? t("minimizeDialog") : t("maximizeDialog")
					}
					onClick={onMaximize}
				>
					{isMaximized ? <XIcon /> : <MaximizeIcon />}
				</Button>
			) : null}
			{canScrollNext || canScrollPrev ? (
				<Button
					variant="iconOnly"
					aria-label={t("prevSlide")}
					disabled={!canScrollPrev}
					onClick={onScrollPrev}
				>
					<NavLeftIcon />
				</Button>
			) : null}
			{canScrollNext || canScrollPrev ? (
				<Button
					variant="iconOnly"
					aria-label={t("nextSlide")}
					disabled={!canScrollNext}
					onClick={onScrollNext}
				>
					<NavRightIcon />
				</Button>
			) : null}
		</div>
	);
};

export default CarouselNavigation;
