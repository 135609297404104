"use client";

import { useMedia } from "react-use";
import { getFragmentData, graphql } from "src/__generated__";
import { buttonVariants } from "src/components/ButtonNew/Button";
import IntroBlock from "src/components/IntroBlock/IntroBlock";
import Link from "src/components/Link/Link";
import BaseSection from "src/components/sections/BaseSection";
import CardGrid from "src/components/sections/CardGridSection/CardGrid";
import { ContentContainerTw } from "src/components/styled/ContentContainer";
import { getColorTheme } from "src/styles/variables";
import type { FragmentType } from "src/__generated__";

export const CardGridSectionFragment = graphql(`
	fragment CardGridSectionItem on CardGridSection {
		id
		spacingTop
		headingAddOn
		heading
		gridDescription: description
		action {
			... on CardAction {
				id
				label
				url
			}
		}
		cards {
			...CardItem
		}
		cardsGridColumns: gridColumns
		gridSizing
		gridColorTheme: colorTheme
		spacingBottom
	}
`);

export const CardFragment = graphql(`
	fragment CardItem on Card {
		id
		name
		headline
		labels
		cardDescription: description
		action {
			... on CardAction {
				id
				label
				url
			}
		}
		websiteUrl
		canShare
		images {
			...CmsImageFields
		}
	}
`);

const GRID_GAP_REM = 1.6;

export const CardGridSection: React.FC<{
	section: FragmentType<typeof CardGridSectionFragment>;
}> = ({ section }) => {
	const {
		spacingTop,
		headingAddOn,
		heading,
		gridDescription,
		action,
		cards,
		cardsGridColumns,
		gridSizing: cardsGridSizing,
		gridColorTheme,
		spacingBottom,
	} = getFragmentData(CardGridSectionFragment, section);

	const isMin1100 = useMedia("(min-width: 1100px)", false);
	const noOfGridColumns = isMin1100 ? cardsGridColumns : 2;

	const cardsData = cards.map((d) => getFragmentData(CardFragment, d));

	const gridSizing = cardsGridSizing ?? "auto-fit";
	const totalGapRem = GRID_GAP_REM * (noOfGridColumns - 1);
	const cardWidthPercentage = 100 / noOfGridColumns;
	const orphanCards =
		cardsData.length && gridSizing === "auto-fit"
			? cardsData.length % noOfGridColumns
			: 0;
	const sliceIndex = cardsData.length - orphanCards;

	return (
		<BaseSection
			paddingTop={spacingTop}
			paddingBottom={spacingBottom}
			style={{
				"--card-grid-fg": getColorTheme(gridColorTheme).fg,
				"--card-grid-bg": getColorTheme(gridColorTheme).bg,
			}}
			className="bg-[var(--card-grid-bg)] text-[var(--card-grid-fg)]"
		>
			<ContentContainerTw>
				<div className="flex flex-col gap-10 md:gap-20">
					<IntroBlock
						headingAddOn={headingAddOn ?? undefined}
						heading={heading}
						description={gridDescription ?? undefined}
					>
						{action ? (
							<Link
								to={action.url}
								className={buttonVariants({ isLink: true })}
							>
								{action.label}
							</Link>
						) : null}
					</IntroBlock>

					{cardsData.length ? (
						<div
							style={{
								"--card-grid-gap": `${GRID_GAP_REM}rem`,
								"--card-grid-grid-sizing": gridSizing,
								"--card-grid-card-min-width": `calc(${cardWidthPercentage}% - ${totalGapRem}rem + 1px)`,
							}}
							className="flex flex-col gap-[var(--card-grid-gap)]"
						>
							<CardGrid
								cardsData={cardsData.slice(0, sliceIndex)}
							/>
							{orphanCards > 0 ? (
								<CardGrid
									cardsData={cardsData.slice(sliceIndex)}
								/>
							) : null}
						</div>
					) : null}
				</div>
			</ContentContainerTw>
		</BaseSection>
	);
};
