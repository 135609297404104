import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { fonts } from "../../../styles/variables";

export const EventMeta = styled.div`
	order: 3;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	${mediaQuery.xl} {
		flex: 1;
		order: unset;
	}

	> p {
		text-overflow: ellipsis;
		white-space: nowrap;
		flex-grow: 0;
		overflow: hidden;
		max-width: 90%;
	}
`;

export const EventTitle = styled.p`
	font-size: ${fonts.fontSize.px24};
	line-height: ${fonts.lineHeight.regular3};
	font-weight: ${fonts.fontWeight.bold};
	margin: 0;
`;
