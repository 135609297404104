import { codeToHtml, bundledLanguages } from "shiki/bundle/full";

const bundledLanguageKeys = Object.keys(bundledLanguages);

export const highlight = async (
	code: string | Array<string>,
	language: string,
) => {
	return codeToHtml(typeof code === "string" ? code : code.join(), {
		lang: bundledLanguageKeys.includes(language) ? language : "text",
		theme: "nord",
	});
};
