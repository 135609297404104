import { startTransition, useCallback } from "react";
import { usePathname } from "src/i18n/routing";
import type { Locale } from "src/i18n/routing";

export const useChangeLocale = (
	localizedPathname: Record<string, Record<string, string>>,
) => {
	const pathname = usePathname();

	const onChangeLocale = useCallback(
		(newLocale: Locale) =>
			startTransition(() => {
				const newPathname =
					localizedPathname[pathname.replace(/\/$/, "")]?.[ // Remove trailing slash, see https://github.com/amannn/next-intl/issues/668
						newLocale
					] ?? "/";

				// this doesn't use the router from next/navigation because the Iubenda Cookie Banner isn't shown otherwise!
				window.location.replace(
					`/${newLocale}${newPathname}${window.location.search}`,
				);
			}),
		[localizedPathname, pathname],
	);

	return { pathname, onChangeLocale };
};
