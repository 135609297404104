import { css } from "@emotion/react";
import React from "react";
import ReactMarkdown from "react-markdown";
import { mediaQuery } from "../../../styles/mixins";
import { colors, dimensions, fonts } from "../../../styles/variables";

const descriptionStyles = css`
	font-size: ${fonts.fontSize.px20};
	font-weight: ${fonts.fontWeight.bold};
	line-height: ${fonts.lineHeight.regular3};
	margin-top: ${dimensions.spacing.px80};
	z-index: ${dimensions.zIndex.aboveDefault};

	p:last-child {
		margin-bottom: 0;
	}

	a {
		color: ${colors.white};
		padding-bottom: ${dimensions.border.padding};
	}

	a:hover,
	a:active {
		text-decoration: none;
	}

	${mediaQuery.lg} {
		font-size: ${fonts.fontSize.px24};
	}
`;

const Description: React.FC<{ children: string }> = ({ children }) => (
	<ReactMarkdown css={descriptionStyles}>{children}</ReactMarkdown>
);

export default Description;
