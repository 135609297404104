"use client";

import { css } from "@emotion/react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { AnimatedIllustrationBackground } from "./AnimatedIllustrationBackground";
import AnimatedIllustrationContainer from "./AnimatedIllustrationContainer";
import FloatingCircle from "./FloatingCircle";
import { StaticImage } from "./StaticImage";
import TextContainer from "./TextContainer";
import { mediaQuery } from "../../../styles/mixins";
import { colors, getColorTheme } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import Title from "../../Title/Title";
import { getImageAlt } from "../../server.helpers";
import ContentContainer from "../../styled/ContentContainer";
import HeadingAddOn from "../../styled/HeadingAddOn";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

const styleContentContainer = css`
	margin-bottom: 45px;
	${mediaQuery.md} {
		margin-bottom: 0;
	}
`;

export const KeyTopicsSectionFragment = graphql(`
	fragment KeyTopicsSectionItem on KeyTopicsSection {
		__typename
		id
		anchor
		spacingTop
		spacingBottom
		listItems {
			headlineAddOn
			headline
			illustration: icon {
				...CmsImageFields
			}
			description: text
		}
		keyTopicsColorTheme: colorTheme
	}
`);

export const KeyTopicsSection: React.FC<{
	section: FragmentType<typeof KeyTopicsSectionFragment>;
}> = ({ section }) => {
	const {
		anchor,
		listItems,
		spacingTop,
		spacingBottom,
		keyTopicsColorTheme,
	} = getFragmentData(KeyTopicsSectionFragment, section);

	return (
		<BaseSection
			paddingTop={spacingTop}
			paddingBottom={spacingBottom}
			style={{
				"--color-theme-fg": keyTopicsColorTheme
					? getColorTheme(keyTopicsColorTheme).fg
					: "inherit",
				"--color-theme-bg": keyTopicsColorTheme
					? getColorTheme(keyTopicsColorTheme).bg
					: "inherit",
			}}
			className="bg-[var(--color-theme-bg)] text-[var(--color-theme-fg)]"
		>
			<Anchor id={anchor} />
			{listItems.map(
				(
					{ headlineAddOn, headline, description, illustration },
					index,
				) => {
					const resolvedIllustration = getFragmentData(
						CmsImageFields,
						illustration,
					);
					const isSvg =
						(resolvedIllustration &&
							resolvedIllustration.mimeType?.includes("svg")) ??
						true;

					const contentContainerProps = {
						css: styleContentContainer,
						[isSvg ? "flexDirectionMd" : "flexDirectionLg"]:
							index % 2 ? "row-reverse" : "row",
					};

					const textContainerProps = {
						[isSvg ? "widthMd" : "widthLg"]: isSvg ? "66%" : "50%",
						[isSvg ? "paddingMd" : "paddingLg"]: "50px",
					};

					return (
						<ContentContainer
							key={description}
							{...contentContainerProps}
						>
							{resolvedIllustration &&
								(isSvg ? (
									<AnimatedIllustrationBackground>
										<AnimatedIllustrationContainer>
											<FloatingCircle />
											<CmsImage
												alt=""
												image={resolvedIllustration}
												contain
												loading="lazy"
												role="none"
											/>
										</AnimatedIllustrationContainer>
									</AnimatedIllustrationBackground>
								) : (
									<StaticImage>
										<CmsImage
											image={resolvedIllustration}
											alt={getImageAlt(
												resolvedIllustration,
											)}
										/>
									</StaticImage>
								))}
							<TextContainer {...textContainerProps}>
								<Title
									headingLevel="h2"
									margin="0 0 20px 0"
									marginLg="0 0 32px 0"
									color={
										keyTopicsColorTheme
											? getColorTheme(keyTopicsColorTheme)
													.fg
											: undefined
									}
								>
									{headlineAddOn && (
										<HeadingAddOn
											addOn={headlineAddOn}
											color={colors.blueberry}
										/>
									)}
									{headline}
								</Title>

								<Markdown source={description} />
							</TextContainer>
						</ContentContainer>
					);
				},
			)}
		</BaseSection>
	);
};
