import { useState } from "react";
import DesktopNavigationWrapper from "src/components/Navigation/DesktopNavigationWrapper";
import NavLinks from "src/components/Navigation/NavLinks";
import { getActiveTopLevelRouteName } from "src/components/helpers";
import type { HeaderFieldsFragment, PageType } from "src/__generated__/graphql";
import type { NavLink } from "src/components/Navigation/Navigation";
import type { Locale } from "src/i18n/routing";

type DesktopNavigationProps = {
	mainNavLinks: Array<NavLink>;
	secondaryNavLinks: Array<NavLink>;
	items: HeaderFieldsFragment["items"];
	pathname: string;
	disableLanguageSwitch?: boolean;
	currentPageType?: PageType;
	onChangeLocale: (locale: Locale) => void;
};

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
	mainNavLinks,
	secondaryNavLinks,
	items,
	pathname,
	disableLanguageSwitch,
	currentPageType,
	onChangeLocale,
}) => {
	const [selectedLinkRouteName, setSelectedLinkRouteName] = useState<
		string | undefined
	>(() => getActiveTopLevelRouteName(items, pathname));

	return (
		<div className="hidden md:block">
			<DesktopNavigationWrapper
				mainNav={
					<NavLinks
						links={mainNavLinks}
						currentPageType={currentPageType}
						selectedLinkRouteName={selectedLinkRouteName}
						setSelectedLinkRouteName={setSelectedLinkRouteName}
					/>
				}
				secondaryNav={
					<NavLinks
						links={secondaryNavLinks}
						size="small"
						selectedLinkRouteName={selectedLinkRouteName}
						setSelectedLinkRouteName={setSelectedLinkRouteName}
					/>
				}
				disableLanguageSwitch={disableLanguageSwitch}
				onChangeLocale={onChangeLocale}
			/>
		</div>
	);
};

export default DesktopNavigation;
