import type { ReactNode } from "react";

type WorkshopTileProps = {
	children: ReactNode;
};

const WorkshopTile: React.FC<WorkshopTileProps> = ({ children }) => {
	return (
		<div className="flex w-full flex-col gap-8 rounded-xl border border-gray-nav bg-white px-5 py-8 [&_.graphcms-image-wrapper]:h-auto">
			{children}
		</div>
	);
};

export default WorkshopTile;
