import { cn, tw } from "@peerigon/pupper/tailwind";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import React, { useEffect } from "react";
import Link from "src/components/Link/Link";
import type { NavAnchorLinkColor } from "src/__generated__/graphql";

export type LandingPageNavLink = {
	label: string;
	anchor: string;
	color: NavAnchorLinkColor;
};

type LandingPageNavLinksProps = {
	links: Array<LandingPageNavLink>;
	pathname: string;
};

const landingPageNavLinkColor = {
	none: tw`bg-transparent text-black`,
	mint: tw`bg-mint text-black`,
	blueberry: tw`bg-blueberry text-white`,
	mintBlueberry: tw`bg-gradient-to-l from-blueberry to-mint text-white hover:bg-none`,
};

const LandingPageNavLinks: React.FC<LandingPageNavLinksProps> = ({
	links,
	pathname,
}) => {
	useEffect(() => {
		const handleScroll = () => {
			const anchors = document.querySelectorAll("div[data-anchor]");

			const activeAnchor = Array.from(anchors)
				.map((section) => {
					const id = section.id;
					const yOffset =
						section.getBoundingClientRect().top + window.scrollY;
					return { id, yOffset };
				})
				.filter(({ yOffset }) => yOffset <= window.scrollY + 300)
				.at(-1);

			const activeNavLink = document.querySelector("a.active-nav-link");
			activeNavLink?.classList.remove("active-nav-link");

			if (activeAnchor) {
				const link = document.querySelector(
					`a[href*="${pathname}/#${activeAnchor.id}"]`,
				);
				link?.classList.add("active-nav-link");
			}
		};

		handleScroll();
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [pathname]);

	return (
		<ScrollAreaPrimitive.Root className="relative overflow-hidden">
			<ScrollAreaPrimitive.Viewport className="h-full w-full px-6 py-0.5">
				<div
					className={cn(
						"relative isolate flex flex-shrink-0 flex-row items-center gap-0.5",
					)}
				>
					{links.map((link) => {
						return (
							<Link
								key={`${link.label}${link.anchor}`}
								to={`${pathname}${link.anchor}`}
								className={cn(
									"z-header flex h-[35px] cursor-pointer select-none items-center whitespace-nowrap rounded-full border-none p-3 transition-colors disable-anchor-shadow-styles",
									"hover:bg-gray-nav hover:text-black active:bg-black active:text-white",
									"outline-1 outline-black focus-visible:!rounded-full focus-visible:!outline-1 focus-visible:!outline-black",
									"text-base leading-[11px] focus:rounded-full focus:outline-1 focus:outline-black",
									landingPageNavLinkColor[link.color],
								)}
								onClick={(e) => {
									e.preventDefault();
									const id = new URL(e.currentTarget.href)
										.hash;
									const anchor = document.querySelector(
										`div[data-anchor]${id}`,
									);
									anchor?.scrollIntoView({
										behavior: "smooth",
									});
								}}
							>
								{link.label}
							</Link>
						);
					})}
				</div>
			</ScrollAreaPrimitive.Viewport>
			<ScrollAreaPrimitive.Scrollbar orientation="horizontal">
				<ScrollAreaPrimitive.Thumb />
			</ScrollAreaPrimitive.Scrollbar>
		</ScrollAreaPrimitive.Root>
	);
};

export default LandingPageNavLinks;
