import React from "react";
import { Components } from "react-markdown";
import { GridCenteredElement } from "../../styled/Grid";

const TableRenderer: Components["table"] = (props) => {
	// ToDo: style tables according to design
	return (
		<GridCenteredElement>
			<table className="text-size-7 font-lighter leading-paragraph [&_th]:font-bold">
				{props.children}
			</table>
		</GridCenteredElement>
	);
};

export default TableRenderer;
