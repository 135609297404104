import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef } from "react";

type LabelProps = ComponentPropsWithoutRef<"button"> &
	VariantProps<typeof labelVariants>;

const labelVariants = cva(
	[
		"inline-flex shrink-0 items-center justify-center gap-px-10 whitespace-nowrap rounded-full px-2 text-xs font-regular leading-larger transition-colors",
		"focus-visible:!rounded-full focus-visible:!outline-1 focus-visible:!outline-black",
		"focus:rounded-full focus:outline-1 focus:outline-black",
	],
	{
		variants: {
			isActive: {
				false: [
					"bg-gray-nav text-black",
					"enabled:hover:bg-black enabled:hover:text-white",
				],
				true: ["bg-mint text-black"],
			},
			isInteractive: {
				true: [
					"select-none disabled:cursor-not-allowed disabled:opacity-20",
					"enabled:active:bg-mint enabled:active:text-black",
				],
				false: ["hover:cursor-default"],
			},
		},
		defaultVariants: {
			isActive: false,
			isInteractive: false,
		},
	},
);

const Label: React.FC<LabelProps> = ({
	type = "button",
	className,
	isActive,
	isInteractive,
	...props
}) => {
	return (
		<button
			// eslint-disable-next-line react/button-has-type
			type={type}
			className={labelVariants({
				isActive,
				isInteractive,
				className,
			})}
			disabled={!isInteractive}
			{...props}
		/>
	);
};

export default Label;
