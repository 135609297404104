import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { FontStyle } from "src/__generated__/graphql";
import { italic } from "../../../styles/mixins";
import { fonts } from "../../../styles/variables";

export type StyleProps = {
	fontStyle: FontStyle;
};

export const StyledStaticFigure = styled("span", {
	shouldForwardProp: isPropValid,
})<StyleProps>`
	flex-grow: 1;
	${({ fontStyle }) => (fontStyle.endsWith("italic") ? italic : "")};
	font-weight: ${({ fontStyle }) =>
		fontStyle.startsWith("bold")
			? fonts.fontWeight.bold
			: fonts.fontWeight.light};
`;
