import { cn } from "@peerigon/pupper/tailwind";
import { getFragmentData } from "src/__generated__";
import Card from "src/components/Card/Card";
import { CmsImageFields } from "src/components/Image/CmsImage";
import type { CardItemFragment } from "src/__generated__/graphql";

type CardGridProps = {
	cardsData: Array<CardItemFragment>;
};

const CardGrid: React.FC<CardGridProps> = ({ cardsData }) => {
	return (
		<div
			className={cn(
				"grid grid-cols-1 gap-[var(--card-grid-gap)] md:grid-cols-[repeat(var(--card-grid-grid-sizing),_minmax(var(--card-grid-card-min-width),_1fr))]",
			)}
		>
			{cardsData.map((card) => {
				const imagesData = card.images.map((d) =>
					getFragmentData(CmsImageFields, d),
				);
				const labels = card.labels.map((label) => ({
					name: label,
					isActive: false,
				}));

				return (
					<Card
						key={card.id}
						name={card.name ?? undefined}
						headline={card.headline}
						labels={labels}
						description={card.cardDescription ?? undefined}
						action={card.action ?? undefined}
						websiteUrl={card.websiteUrl ?? undefined}
						canShare={card.canShare ?? false}
						imagesData={imagesData}
					/>
				);
			})}
		</div>
	);
};

export default CardGrid;
